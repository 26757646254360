<template>
  <div receiver-signicat>
    <template v-if="isEidvProcessing">
      <SignicatEidvProcessing />
    </template>
  </div>
</template>

<script>
import { parseQuery } from '@shared/utils/urlUtils.mjs';
import { signicatStatus, signicatStep } from '@/constants/base/onboarding/kyc';
import FontIcon from '@shared/components/common/FontIcon.vue';
import SignicatEidvProcessing from '@/views/components/pages/on-boarding/template/kyc/signicat/SignicatEidvProcessing.vue';
import { getCookie } from '@shared/utils/cookieUtils.mjs';

export default {
  name: 'ReceiverSignicat',
  lexicon: 'onBoarding.signicat.processing',
  components: { SignicatEidvProcessing },
  data() {
    return {
      isEidvProcessing: false,
      isFromMobileApp: getCookie('isFromMobileApp') || this.$route.query.isFromMobileApp,
      isFromClientApp: getCookie('isFromClientApp') || this.$route.query.isFromClientApp,
      redirectUri: getCookie('redirectUri') || this.$route.query.redirectUri,
    };
  },
  async mounted() {
    // this.$sentry.warning(`Receiver Signicat Url : ${location.href}`); // sentry logging 처리 주석
    const { step, status } = parseQuery(location.search);
      const tempStatus = (Array.isArray(status) ? status[0] : status)?.toLowerCase();

    console.log('%csignicat receiver result', 'color:yellow', step, tempStatus);

    if (step === signicatStep.IdinVerification) {
      const isFromMobileApp = this.isFromMobileApp;
      const isFromClientApp = this.isFromClientApp;
      const redirectUri = this.redirectUri;

      this.replaceRouteName('Signicat', null, { ...this.$route.query, step, status: tempStatus, isFromMobileApp, isFromClientApp, redirectUri });
    } else {
      if (step === signicatStep.EidvVerification && tempStatus.toLowerCase() === signicatStatus.Processing.toLowerCase()) this.isEidvProcessing = true;
      window.parent.postMessage({ key: 'RECEIVER_SIGNICAT', step, status: tempStatus }, '*');
    }
  }
};
</script>
<style lang="less">
@import '~@/less/proj.less';
[receiver-signicat] { .bgc(white); .wh(100vw, 100vh); .abs(); .lt(0,0);
  //.flex-center(); .tc(); .wh(100vw, 100vh); .ml(calc(-50vw + 50%)); .mt(calc(-50vh + 50%));
}
</style>
