import { setGTM } from '@/plugins/tracking';
import { routeNames } from '@/constants/base/onboarding/onBoardingSiteMap';
import { siteIds } from '@/constants/base/siteMap';
import { appendQuery } from '@shared/utils/urlUtils.mjs';
import { restrictType } from '@/constants/base/onboarding/kyc';
import {apiErrorCode} from "@/constants/base/apiErrorCode";

export class OnboardingServiceController {
  #services;
  #scope;
  #router;
  #store;
  #siteMap;
  #siteInfo;
  #site;
  #step;
  #expireSteps;
  #orderInfo;
  #model;
  #kycStep;
  #isMobile;
  #isLocal;
  #widgetUrl;
  #selectedLang;
  #error;

  #structure;
  #modelProxy;
  #routeNames;
  #causes;

  async setScope(scope) {
    this.#services = scope.$services;
    this.#router = scope.$router;
    this.#store = scope.$store;
    this.#model = scope.$model;
    this.#scope = scope;

    this.#siteInfo = this.#store.state.env.siteInfo;
    this.#site = this.#store.state.env.site;
    this.#kycStep = this.#store.state.user?.userInfo?.KycStatus;
    this.#isMobile = this.#store.state.browser?.mobile;
    this.#isLocal = this.#store.state.env?.env === 'local';
    this.#widgetUrl = this.#store.state.env?.widgetUrl;
    this.#selectedLang = this.#store.state.query?.selectedLang;
    this.#error = false;

    setGTM(this.#site, this.#siteInfo, this.#router);
  }

  async reset() {
    await this.#getOrder();
    await this.#setAutomation();
  }

  async checkCustomStep() {
    const { customStep } = this.#store.state.query;
    if (!customStep) await this.reset();
  }

  async updateStructure(structure, replace) {
    const init = !this.#structure;
    this.#structure = structure;
    if(replace) {
      await this.reset();
      this.#modelProxy = null;
      await this.injectionMyInfo();
    }
    if(init) await this.injectionMyInfo(this.#store.state.user.userInfo);
  }

  async injectionMyInfo(info) {
    const r = !info ? await this.apiRequest('onboarding', 'getMyInfo', true) : info;
    this.#store.commit('user/setUserInfo', r);
    this.#kycStep = r?.KycStatus;
    Object.keys(r).forEach(o => {
      if (Array.isArray(r[o])) this.#structure.model[o] = r[o];
      else if (typeof r[o] === 'object') Object.keys(r[o]).forEach(c => this.#structure.model[c] = this.#getValue(r[o][c]));
      else this.#structure.model[o] = this.#getValue(r[o]);
    });
    this.#structure.model.CountryOfBirth = this.#structure.model.Country;

    /** pep type injection*/
    if (this.#site === siteIds.GGPDE) {
      this.#structure.model.pepType = this.#structure.model.pepType || [];
      const screenResult = this.#structure.model.ScreeningResult || [];

      if (screenResult.includes('Pep')) this.#structure.model.pepType.push('PEP');
      if (screenResult.includes('Watch')) this.#structure.model.pepType.push('Sanktionen');
    }
  }

  async getModel(replace = false, params = null) {
    return await this.#model.getModel(this.#scope, {application: 'onboarding', orderRouteNames: this.#routeNames, siteId: this.#site, replaceModel: replace ? this.#structure.model : null, params});
  }

  async setRecaptcha(action) {
    return await new Promise(resolve => {
      window.grecaptcha.enterprise.ready(async () => {
        resolve(await this.#services.captcha.recaptchaEnterprise(action));
      });
    });
  }

  async apiRequest(service, api, model, config) {
    try {
      if (!this.#error) { // 에러 발생 후에 중복 호출 방지 처리
        const r = await this.#getApiResponse(service, api, model, config);
        if (r) {
          this.#error = r.error;
          if (this.#error) {
            const errorInfo = this.errorHandler(r);
            if (errorInfo) await this.#router.replace({ name: errorInfo.path, params: errorInfo.params, query: errorInfo.query });
          }
        }
        return r;
      }
    } catch (e) { throw e; }
  }

  async #getApiResponse(service, api, model, config) {
    try { return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.#services[service][api](model, config); }
    catch (e) { throw e; }
  }

  errorHandler(r, move) {
    const { code, desc, key, CustomerErrorParameters, errorTemplate } = r;
    if(errorTemplate?.path) {
      const queries = errorTemplate.queries || {};
      const values = { path: r.errorTemplate.path, param: { desc, errorTemplate, ci: errorTemplate.ci, cci: errorTemplate.cci }, query: { p: CustomerErrorParameters ? encodeURIComponent(CustomerErrorParameters) : undefined, ...queries, desc} };
      if(move) {
        this.#scope.replaceRouteName(values.path, values.param, values.query);
        return true;
      }
      return values;
    }
    return false;
  }

  /**
   * address Error Handler
   * @param {object} r - error 정보
   * @returns {Promise<boolean>}
   */
  addressErrorHandler(r) {
    const { code, desc, key, CustomerErrorParameters, errorTemplate } = r;
    /*
    * INVALID_STREET_NAME
      INVALID_STREET_NUMBER
      INVALID_HOUSE_NUMBER
      MISSING_EXTRA_ADDRESS
      INVALID_BARANGAY
      INVALID_ADDRESS
      INVALID_ADDRESS_LENGTH
      INVALID_ADDRESS_CHARACTER
      INVALID_STATE
      INVALID_BUILDING
      INVALID_CITY
      INVALID_CITY_LENGTH
      INVALID_CITY_CHARACTER
      INVALID_POSTAL_CODE
      INVALID_POSTAL_CODE_LENGTH
      INVALID_POSTAL_CODE_CHARACTER
    */
    switch (key) {
      case apiErrorCode.USER_INFO_ALREADY_EXIST:
      case apiErrorCode.FAILED_SELF_EXCLUSION_CHECK:
      case apiErrorCode.REJECT_FROM_BGC:
        this.replaceRouteName('VerificationFailed', {desc, errorTemplate}, { p: CustomerErrorParameters ? encodeURIComponent(CustomerErrorParameters) : undefined });
        break;
      case apiErrorCode.INVALID_STREET_NAME:
      case apiErrorCode.INVALID_STREET_NUMBER:
        this.streetErrorMsg = this.$t(key);
        break;
      case apiErrorCode.INVALID_BARANGAY:
        this.districtErrorMsg = this.$t(key);
        break;
      case apiErrorCode.INVALID_HOUSE_NUMBER:
        this.houseNumberErrorMsg = this.$t(key, {fieldName: this.$t('houseNumber')});
        break;
      case apiErrorCode.INVALID_ADDRESS:
      case apiErrorCode.INVALID_ADDRESS_LENGTH:
      case apiErrorCode.INVALID_ADDRESS_CHARACTER:
        this.addressErrorMsg = this.$t(key, {fieldName: this.$t('address'), length: this.addressPreset.maxLength});
        break;
      case apiErrorCode.INVALID_STATE:
        this.stateErrorMsg = this.$t(key, {fieldName: this.$t('stateProvince')});
        break;
      case apiErrorCode.INVALID_CITY:
      case apiErrorCode.INVALID_CITY_LENGTH:
      case apiErrorCode.INVALID_CITY_CHARACTER:
        this.cityErrorMsg = this.$t(key, {fieldName: this.$t('city'), length: this.cityPreset.maxLength});
        break;
      case apiErrorCode.INVALID_POSTAL_CODE:
      case apiErrorCode.INVALID_POSTAL_CODE_LENGTH:
      case apiErrorCode.INVALID_POSTAL_CODE_CHARACTER:
        // PH는 length 4, CZ는 length 5
        this.postalCodeErrorMsg = this.$t(key, {fieldName: this.$t('postalCode'), length: this.postalPreset.maxLength});
        break;
    }

    return true;
  }

  async errorRouteReplace(r) {
    const info = this.errorHandler(r);
    if (info) return await this.#router.replace({ name: info.path, params: info.params, query: info.query });
    return false;
  }

  async #getOrder() {
    let restrict = this.#store.state.query.restrict;
    const additional = this.#store.state.query.additional;

    // GGPOK일 경우 cashier에서 Deposit 으로 진입 시 None으로 변경
    if (this.#site === siteIds.GGPOK && !!additional && restrict === restrictType.Deposit) {
      restrict = restrictType.None;
      this.#store.commit('query/setRestrict', restrict);
    }

    const r = await this.apiRequest('onboarding', 'getOrder', restrict);
    this.#routeNames = r.VerificationOrder;
    this.#causes = r.Causes;

    this.#store.commit('env/setRouteNames', this.#routeNames);
    this.#store.commit('env/setCauses', this.#causes);
  }

  async getExpireOrder() {
    const r = await this.apiRequest('kyc', 'getOrderForKycExpire');
    if (Array.isArray(r)) {
      this.#expireSteps = [];
      r.forEach((item, id) => {
        this.#expireSteps.push(item);
        if (item === routeNames.verifyContactEmail) this.#expireSteps.push(routeNames.verifyCode);
      });
    }

    return this.#expireSteps;
  }

  /**
   * 온보딩 자동화 스탭이 내려올 경우 그에 대한 대응 후 스탭 재조회
   * @returns {Promise<boolean>}
   */
  async #setAutomation() {
    if (this.#routeNames?.[0] === 'AutoOnboarding') {
      await this.apiRequest('kyc', 'verificationAutomation');
      await this.#getOrder();
    }
  }

  #getValue(value) {
    return ['Undefined', 'undefined', undefined, 'Null', 'null', null].includes(value) ? null : value;
  }

  async redirectCashier() {
    if (typeof window === 'undefined') return;

    const deviceType = this.#isMobile ? 'mobileType' : 'pcType';
    const r = await this.#services.token.getToken();
    const token = r?.data?.npToken?.accessToken;
    window.location.href = `${this.#widgetUrl}/Cashier?ViewType=${deviceType}&token=${token}&language=${this.#selectedLang}`;
  }
}

export default {
  install(Vue) {
    Vue.prototype.$onboarding = new OnboardingServiceController();
  }
};