<template>
  <ul gp-radio-group :class="[{'vertical' : this.isVertical}, { 'horizontal' : !this.isVertical }]">
    <li v-for="(item, idx) in list" :data-value="item.value.toString()" :key="idx" @click="click(item)" :class="[{'disabled-item':isUnauthenticated(item)}, 'item']">
      <component :is="getComponent" :type="item.type" :active="isActive(item.value)" :rules="rules" :label="item.label" v-bind="item" :is-authenticated="!isUnauthenticated(item)" :maskedValue="item.maskedValue" :mobileType="mobileType" @update="update" @changeMobile="changeMobile" @open="open" />
    </li>
  </ul>
</template>

<script>
import RadioButton from '@/views/components/gg-pass/RadioButton.vue';
import Specific from '@shared/types/Specific';
export default {
  name: 'RadioList',
  // components: { RadioButton },
  props: {
    list: { type: Array, default: null },
    value: Specific,
    rules: { type: String, default: null, },
    type: { type: String, default: 'horizontal' },
    customRadio: { type: Specific, default: null },
    mobileType: { type: Number, default: 0 },
  },
  data() {
    return {
      currentValue: null,
    };
  },
  computed: {
    isVertical() {
      return this.type === 'vertical';
    },
    getComponent() {
      return this.customRadio ? this.customRadio : RadioButton;
    },
  },
  watch: {
    'value': 'updateCurrentValue'
  },
  methods: {
    isUnauthenticated(item) {
      return item?.isAuthenticated === false;
    },
    updateCurrentValue() {
      this.currentValue = this.value;
    },
    click(item) {
      // const element = e.target.closest('li');
      // let value = this.getDataValue(element);
      // if (!value) return;
      // if (value === 'true' || value === 'false') {
      //   value = value === 'true';
      // }
      if(this.isUnauthenticated(item)) return;
      this.currentValue = item.value;
      this.$emit('input', item.value);
    },
    getDataValue(element) {
      if (!element) return null;
      const { dataset } = element;
      if (!dataset) return null;
      const { value } = element.dataset;
      return value;
    },
    update(v) {
      this.$emit('update', v);
    },
    changeMobile(v) {
      this.$emit('changeMobile', v);
    },
    isActive(value) {
      return value === this.currentValue;
    },
    open(type){
      this.$emit('open', type);
    }
  },
  mounted() {
    this.currentValue = this.value;
  }

};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-radio-group] { .flex(); .gap(10);
  &.vertical { .flex-dc();}
  &.horizontal {.flex-dr();}

  .item{.pointer();}
  .disabled-item{cursor: not-allowed;
    h3{.c(gray);}
  }
}
</style>