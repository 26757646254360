<template>
  <BaseModal mfa-google-modal :title="$t('myInfo.googleAuthenticator')">
    <template #content>
      <div class="body">
        <DisplayStep :last="last" :first="first" :current="step" />
        <div class="input-group">
          <component :is="getComponent" :options="options" :google-model="googleModel" :google-info="googleInfo" :progress="progress" :google-error="googleError" @next="next" @prev="prev" @verify="verify" />
        </div>
      </div>
    </template>
    <template #floating>
      <div class="gp-modal-floating">
        <GpButton v-if="!isFirst && !isLast" type="line-green" @click="prev">{{ $t('myInfo.prev') }}</GpButton>
        <GpButton type="submit-green" @click="next" :progress="progress" :disabled="disableButton">{{ $t(btnText) }}</GpButton>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { isExcludeGgpass } from '@/utils/baseUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import BaseModal from '@/views/components/gg-pass/modal/BaseModal.vue';
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import specific from '@shared/types/Specific';
import DisplayStep from '@/views/components/pages/my-page/modal/components/DisplayStep.vue';
import DownloadMarket from '@/views/components/pages/my-page/modal/components/DownloadMarket.vue';
import DisplayQRCode from '@/views/components/pages/my-page/modal/components/DisplayQRCode.vue';
import BackupQRCode from '@/views/components/pages/my-page/modal/components/BackupQRCode.vue';
import VerifyGoogleCode from '@/views/components/pages/my-page/modal/components/VerifyGoogleCode.vue';
import SuccessMfa from '@/views/components/pages/my-page/modal/components/SuccessMfa.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import { ggpassLevel, ggpassMethod } from '@/constants/base/my-page';

const LAST = 5;
const FIRST = 1;

const STEP_COMPONENT = {
  1: DownloadMarket,
  2: DisplayQRCode,
  3: BackupQRCode,
  4: VerifyGoogleCode,
  5: SuccessMfa
};

export default {
  name: 'MfaGoogleModal',
  components: { GpButton, DisplayStep, BaseModal },
  props: {
    options: specific
  },
  data() {
    return {
      email: '',
      step: 1,
      last: LAST,
      first: FIRST,
      googleModel: {
        authenticatorCode: null,
      },
      googleError: {
        errorMsg: null,
        error: false,
      },
      googleInfo: {
        QRCodeImageUrl: null,
        ManualEntryKey: null,
      },
      progress: true,
      TextInputBox
    };
  },
  computed: {
    site: state('env', 'site'),
    isFirst() {
      return this.step === this.first;
    },
    isLast() {
      return this.step === this.last;
    },
    isVerifyStep() {
      return this.step === 4;
    },
    btnText() {
      return this.isLast ? 'ok' : 'next';
    },
    getComponent() {
      return STEP_COMPONENT[this.step];
    },
    disableButton() {
      if (!this.isVerifyStep) return false;
      else {
        if (!this.googleModel.authenticatorCode) return true;
        if (this.googleModel.authenticatorCode.length < 6) return true;
        if (this.googleError.error) return true;
        return false;
      }
    },
  },
  watch: {
    'googleModel.authenticatorCode': function () {
      this.googleError.error = false;
      this.googleError.errorMsg = null;
    },
    'step': function () {if (!this.isVerifyStep) this.googleModel.authenticatorCode = null;}
  },
  methods: {
    async initialize() {
      this.progress = true;
      const result = await this.$services.mfa.getGoogleInfo();
      if (result.error) {
        this.$modalCloseAll();
      }
      this.googleInfo.QRCodeImageUrl = result.QRCodeImageUrl;
      this.googleInfo.ManualEntryKey = result.ManualEntryKey;
      this.progress = false;
    },
    verify() {
      // const { successGoogleVerify } = this.options;
      // if (typeof successGoogleVerify !== 'function') return;
      // successGoogleVerify();
      this.$emit('resolve', true);
      // this.$modalCloseAll();
    },
    async next() {
      if (this.isVerifyStep) {
        await this.submit();
        return;
      }
      if (this.isLast) {
        this.verify();
        return;
      }
      if (this.step < this.last) this.step++;
    },
    prev() {
      if (this.step > this.first) this.step--;
    },
    async submit() {
      this.progress = true;
      const result = await this.$services.mfa.sendGoogleAuthentication(this.googleModel.authenticatorCode);

      this.progress = false;
      if (result.error) {
        this.apiErrorHandler(result);
        return;
      } else {
        this.step++;
      }
    },
    apiErrorHandler(error) {
      const { key, desc } = error;
      this.googleError.error = true;

      switch (key) {
        case 'INVALID_PLAYER' :
          return this.googleError.errorMsg = this.$t(key);
        case 'INVALID_VERIFICATIONCODE' :
          return this.googleError.errorMsg = this.$t(key);
        case 'VERIFICATIONCODE_NOT_FOUND' :
          return this.googleError.errorMsg = this.$t(key);
        case 'INTERNAL_ERROR' :
          return this.googleError.errorMsg = this.$t(key);
        case 'UNMATCHED_VERIFICATIONCODE' :
          return this.googleError.errorMsg = this.$t(key);
        default :
          return this.googleError.errorMsg = this.$t('INVALID_VERIFICATION_CODE');
      }
    }
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[mfa-google-modal] {
  > .panel { .h(65%); .rel(); .min-h(490); .max-h(490); .max-w(460);
    @media (max-height: 499px) {
      .min-h(100%);
    }
    .scroll-area {
      .h(calc(100% - 59px)); .flex(); .flex-dc();
      > .body { flex: 1; }
    }
  }
  .input-group { .flex();.flex-dc();.gap(20);.pt(15); .h(100%);
    [gp-template-form] {.ph(0);}
  }
  .success { .flex(); .justify-center(); .items-center(); .flex-dc(); .h(100%);
    > p {.c(black); font-size: 16px !important; .pt(10);}
  }
  .content {flex: 1;}
  .footer { .flex(); .flex-dr(); .gap(20); }

  .gp-modal-floating {.abs(); .b(20); .w(100%); .ph(20); .flex(); .gap(10);}

}
</style>
