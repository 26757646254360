<template>
  <div receiver-payletter>
  </div>
</template>

<script>
import { close } from '@/utils/clientUtil';

export default {
  name: 'ReceiverPayletter',
  async mounted() {
    // this.$sentry.warning(`Receiver Payletter Url : ${location.href}`); // sentry logging 처리 주석
    close(this);
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
</style>