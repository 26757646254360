import { isExcludeGgpass } from '@/utils/baseUtil';
import { ggpassLevel, ggpassMethod } from '@/constants/base/my-page';
import { gpSiteIds } from '@/constants/base/siteMap.mjs';
import { apiErrorCode } from '@/constants/base/apiErrorCode';

export default class MfaService {
  #services;
  #store;
  #proxyNpUserApi;
  #proxyAuthOpApi;

  constructor(services) {
    this.#services = services;
    this.#store = services.store;
    this.#proxyNpUserApi = services.proxyNpUserApi;
    this.#proxyAuthOpApi = services.proxyAuthOpApi;
  }

  /**
   * @id GetMfaConfigRequest
   * @returns {*}
   */
  #getNpMfa() {
    try {
      return this.#proxyNpUserApi.proxyGet('/player/mfaconfig');
    } catch (e) {
      console.log('error : #getNpMfa');
      throw e;
    }
  }

  /**
   * @returns {Promise<*|{error}>}
   */
  async #getGpMfa() {
    try {
      const r = await this.#proxyAuthOpApi.proxyGet('/op/v1/onepass/mfas');
      return r;
    } catch (e) {
      console.log('error : #getGpMfa');
      throw e;
    }
  }

  /**
   * @id GetPlayerGoogleAuthenticationUrlRequest
   * @return {MfaGoogleAuthentication}
   */
  #getNpGoogleAuthentication() {
    try {
      return this.#proxyNpUserApi.proxyGet('/player/google/authentication/url');
    } catch (e) {
      console.log('error : #getNpGoogleAuthentication');
      throw e;
    }
  }

  /**
   * @id EditMfaConfigRequest
   * @param VerificationMethod
   * @param SecurityLevel
   * @param UsualLoginSecurity
   * @param UnusualLoginSecurity
   * @param VerificationCode
   * @returns {Promise<*>}
   */
  async #editNpMfaConfig({ VerificationMethod, SecurityLevel, UsualLoginSecurity, UnusualLoginSecurity, VerificationCode }) {
    try {
      const r = await this.#proxyNpUserApi.proxyPatch('/player/mfaconfig', { VerificationMethod, SecurityLevel, UsualLoginSecurity, UnusualLoginSecurity, VerificationCode }, { silent: true });
      if (r.error && r.key === apiErrorCode.MFA_RESEND_TERM_LIMITED) return { ...r, key: 'limit', value: r.desc.replace(/[^0-9]/g, '') };
      return r;
    } catch (e) {
      console.log('error : #editNpMfaConfig');
      throw e;
    }
  }

  /**
   * @id GetPlayerGoogleAuthenticationVerifyRequest
   * @param MfaCode
   * @returns {Promise<*>}
   */
  async #sendNpGoogleAuthenticationCode(MfaCode) {
    try {
      const r = await this.#proxyNpUserApi.proxyPost('/player/google/authentication/verify', { MfaCode }, { silent: true, });
      return r;
    } catch (e) {
      console.log('error : #sendNpGoogleAuthenticationCode');
      throw e;
    }
  }

  /**
   * @param totpCode { String }
   * */
  #sendGpGoogleAuthenticationCode(totpCode) {
    try {
      const brandId = this.#store.state.env.gpBrand;
      return this.#proxyAuthOpApi.proxyPut('/op/v1/onepass/mfa/google', { totpCode, brandId });
    } catch (e) {
      console.log('error : #sendGpGoogleAuthenticationCode', e);
    }
  }

  /**
   * @param methodType { String }
   * @param level { String }
   * @param email { String }
   * @param countryCode { String }
   * @param mobileNumber { String }
   * @param verificationCode { String }
   * @param VerificationMethod { String }
   * */
  async #setGpMfa(mfa) {
    const isEmail = ggpassMethod[mfa.VerificationMethod] === ggpassMethod.Email;
    const methodType = ggpassMethod[mfa.VerificationMethod];
    const level = ggpassLevel[mfa.SecurityLevel];
    const email = isEmail ? mfa?.Email?.trim() : null;
    const countryNumber = mfa?.CountryNumber ? `+${mfa.CountryNumber}` : null;
    const countryCode = isEmail ? null : countryNumber;
    const mobileNumber = isEmail ? null : mfa.MobileNumber;
    const mfaCode = mfa.VerificationCode ? mfa.VerificationCode : null;
    const verificationToken = mfa.Token ? mfa.Token : null;
    const brandId = this.#store.state.env.gpBrand;
    // EVPUKE일 경우에만 siteID 발송
    const siteId = this.#store.state.env.gpSite === gpSiteIds.EVPUKE ? this.#store.state.env.gpSite : null;
    try {
      const result = await this.#proxyAuthOpApi.proxyPut('/op/v1/onepass/mfa', { methodType, level, email, countryCode, mobileNumber, mfaCode, verificationToken, brandId, siteId });
      if (result.error) {
        if (result.key === apiErrorCode.REQUIRED_VERIFICATION_CODE) {
          return { ...result, error: false };
        } else if (result.key === apiErrorCode.RESEND_TERM_LIMITED) {
          const json = JSON.parse(result.desc2);
          const value = parseInt(json.remainingTime);
          return { ...result, key: 'limit', value };
        }
      }
      return result;
    } catch (e) {
      console.log('error : getMfa', e);
    }
  }

  async sendMfa(payload) {
    const site = this.#store.state.env.site;
    try {
      if (isExcludeGgpass(site)) {
        return await this.#editNpMfaConfig(payload);
      } else {
        return await this.#setGpMfa(payload);
      }
    } catch (e) {
      console.log('error : sendMfa');
      throw e;
    }
  };

  async sendGoogleAuthentication(mfaCode) {
    const site = this.#store.state.env.site;
    try {
      if (isExcludeGgpass(site)) {
        return await this.#sendNpGoogleAuthenticationCode(mfaCode);
      } else {
        return await this.#sendGpGoogleAuthenticationCode(mfaCode);
      }
    } catch (e) {
      console.log('error : sendGoogleAuthentication');
      throw e;
    }
  }

  async getMfa() {
    const site = this.#store.state.env.site;
    try {
      if (isExcludeGgpass(site)) {
        return await this.#getNpMfa();
      } else {
        return await this.#getGpMfa();
      }
    } catch (e) {
      console.log('error : getMfa');
      throw e;
    }
  }

  async getGoogleInfo(payload) {
    const site = this.#store.state.env.site;
    try {
      if (isExcludeGgpass(site)) {
        return await this.#getNpGoogleAuthentication();
      } else {
        const r = await this.createOnePassMfa({
          SecurityLevel: 'High',
          VerificationMethod: 'GoogleAuthentication',
          MethodType: 'GOOGLE'
        });
        return {
          QRCodeImageUrl: r.qrCode,
          ManualEntryKey: r.key,
        };
      }
    } catch (e) {
      console.log('error : getGoogleInfo');
      throw e;
    }
  }

  /**
   * @param Email
   * @param CountryCode
   * @param Token
   * @param VerificationCode
   * @param MobileNumber
   * @param SendVerificationType
   * @returns {Promise<*|{error}|void>}
   */
  async createOnePassMfa({ Email, CountryCode, VerificationCode, MobileNumber, SendVerificationType, MethodType }){
    const isEmail = SendVerificationType === 'EMAIL';
    const email = isEmail ? Email?.trim() : null;
    const countryCode = isEmail ? null : CountryCode;
    const mobileNumber = isEmail ? null : MobileNumber;
    const verificationCode = VerificationCode;
    const brandId = this.#store.state.env.gpBrand;
    const methodType = MethodType;
    // EVPUKE일 경우에만 siteID 발송
    const siteId = this.#store.state.env.gpSite === gpSiteIds.EVPUKE ? this.#store.state.env.gpSite : null;
    try {
      const result = await this.#proxyAuthOpApi.proxyPost('/op/v1/onepass/mfa', { email, countryCode, mobileNumber, verificationCode, brandId, siteId, methodType });
      if (result.error) {
        if (result.key === apiErrorCode.REQUIRED_VERIFICATION_CODE) {
          return {
            ...result,
            error: false,
          };
        } else if (result.key === apiErrorCode.RESEND_TERM_LIMITED) {
          const json = JSON.parse(result.desc2);
          const value = parseInt(json.remainingTime);
          // Resend Term Limited 인 경우, 에러로 처리하지 않기 위해, response 를 다시 만든다.
          return { ...result, key: 'limit', value };
        }
      }
      return result;
    } catch (e) {
      console.log('error : setLoginId', e);
      throw e;
    }
  }
}