<template>
  <div input-balance-limit>
    <LimitTableRow hidden-limit :mode="validationMode" :rules="rules" current-name="_currentLimit" v-model="model.RequestBalanceLimit" :current-value="currentAmount" new-name="_newLimit" :preset="preset" @checked="change" />
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import TextInputBox from '@/views/components/gg-pass/TextInputBox.vue';
import { maximumBySite } from '@/constants/base/responsible-gaming';
import LimitTableRow from '@/views/components/pages/responsible-gaming/template/common/LimitTableRow.vue';
import { siteIds } from '@/constants/base/siteMap';
export default {
  name: 'InputBalanceLimit',
  lexicon: 'onBoarding.setting',
  components: { LimitTableRow },
  props: {
    model: Specific,
    current: Specific,
    currencySymbol: { type: String, default: null }
  },
  data() {
    return {
      TextInputBox
    };
  },
  computed: {
    site: state('env', 'site'),
    isGGPNL() {
      return this.site === siteIds.GGPNL;
    },
    isGGPCZ() {
      return this.site === siteIds.GGPCZ;
    },
    placeHolder() {
      return this.$t('amount');
    },
    currentAmount() {
      return this.createCurrentDisplayAmount(this.current.CurrentBalanceLimit);
    },
    preset() {
      return { placeholder: this.placeHolder, textAlign: 'right', currencySymbol: this.currencySymbol, type: 'number', maxLength: 9 };
    },
    validationMode() {
      return this.site === siteIds.GGPNL ? 'lazy' : 'aggressive';
    },
    rules() {
      let maximum = null;
      let length = 9;
      if (maximumBySite.hasOwnProperty(this.site)) maximum = maximumBySite[this.site];
      let minMax = 'rgMin:1';
      if (maximum !== null) {
        minMax = this.isGGPNL ? `minimumDepositLimit:${this.currencySymbol},1|maximumDepositLimit:${this.currencySymbol},1,${maximum}` : `rgMin:1|rgMax:${maximum}`;
        length = maximum.toString().length;
        return `${minMax}|includeBalanceCharacter`;
      } else return `${minMax}|includeBalanceCharacter`;
    }
  },
  methods: {
    createCurrentDisplayAmount(value) {
      if(!value && value !== 0) return null
      return `${this.currencySymbol}${value}`;
    },
    change(v) {
      this.model.NoLimit = v;
      if (v) this.model.RequestSingleBetLimit = null;
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[input-balance-limit] {.flex(); .flex-dc(); .gap(20);}
</style>