<template>
  <GpFormTemplate total-bet-limit>
    <template #header>
      <GpDescription v-if="description" :message="description" />
      <GpUlDescriptionList v-if="subDescription" :message="subDescription" />
      <GpDescription v-if="structure.config.verifyFreeze" :message="$t('responsibleGaming.verifyFreeze.message')" />
    </template>
    <template #content="{errors}">
      <InputTotalBetLimitTable :rolling="rolling" :currency-symbol="currencySymbol" :model="model" :current="current" :hangTime="hangTimeModel" :errors="errors" />
    </template>
    <template #footer="{validate, invalid}">
      <GpButton type="submit-green" @click="openModal" :disabled="invalid || disabled">{{ $t('submit') }}</GpButton>
    </template>
  </GpFormTemplate>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import InputTotalBetLimitTable from '@/views/components/pages/responsible-gaming/template/inputs/InputTotalBetLimitTable.vue';
import { getCurrencySymbol } from '@shared/utils/currencyUtils.mjs';
import ResponsibleProgressModal from '@/views/components/pages/responsible-gaming/modal/ResponsibleProgressModal.vue';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import Specific from '@shared/types/Specific';
import GpUlDescriptionList from '@/views/components/gg-pass/GpUlDescriptionList.vue';

export default {
  name: 'RgTotalBetLimit',
  lexicon: 'responsibleGaming',
  components: { GpUlDescriptionList, InputTotalBetLimitTable, GpFormTemplate, GpDescription, GpButton },
  props: {
    structure: { type: Specific, default: null },
    title: { type: String, default: null },
    description: { type: String, default: null, }
  },
  data() {
    return {
      errorMsg: null,
      rolling: null,
      currencySymbol: null,
      current: {
        Currency: null,
        CurrentMonthlyTotalBetLimit: null,
        CurrentWeeklyTotalBetLimit: null,
        CurrentDailyTotalBetLimit: null,
        MonthlyTotalBetLimitApplyAt: null,
        WeeklyTotalBetLimitApplyAt: null,
        DailyTotalBetLimitApplyAt: null,
      },
      model: {
        RequestMonthlyTotalBetLimit: null,
        RequestWeeklyTotalBetLimit: null,
        RequestDailyTotalBetLimit: null,

        MonthlyNoLimit: false,
        WeeklyNoLimit: false,
        DailyNoLimit: false,
      },
    };
  },
  computed: {
    site: state('env', 'site'),
    hangTimeModel() {
      return {
        'monthly': [this.structure.config.monthlyLowerHangTime, this.structure.config.monthlyUpperHangTime],
        'weekly': [this.structure.config.weeklyLowerHangTime, this.structure.config.weeklyUpperHangTime],
        'daily': [this.structure.config.dailyLowerHangTime, this.structure.config.dailyUpperHangTime],
      };
    },
    calculateList() {
      return [
        { type: 'monthly', current: this.current.CurrentMonthlyTotalBetLimit, request: this.model.RequestMonthlyTotalBetLimit, noLimit: this.model.MonthlyNoLimit, valueType: 'amount', currencySymbol: this.currencySymbol, rolling: this.rolling, },
        { type: 'weekly', current: this.current.CurrentWeeklyTotalBetLimit, request: this.model.RequestWeeklyTotalBetLimit, noLimit: this.model.WeeklyNoLimit, valueType: 'amount', currencySymbol: this.currencySymbol, rolling: this.rolling, },
        { type: 'daily', current: this.current.CurrentDailyTotalBetLimit, request: this.model.RequestDailyTotalBetLimit, noLimit: this.model.DailyNoLimit, valueType: 'amount', currencySymbol: this.currencySymbol, rolling: this.rolling, },
      ];
    },
    subDescription() {
      if (this.rolling === 'Rolling') {
        return this.$t('responsibleGaming.subDescription.dayTotalBet', { utcInfo: this.structure.config.timeZoneType });
      } else {
        return this.$t('responsibleGaming.subDescription.calendar', { utcInfo: this.structure.config.timeZoneType });
      }
    },
    disabled() {
      const {
        CurrentDailyTotalBetLimit,
        CurrentWeeklyTotalBetLimit,
        CurrentMonthlyTotalBetLimit,
      } = this.current;
      const {
        RequestDailyTotalBetLimit,
        RequestWeeklyTotalBetLimit,
        RequestMonthlyTotalBetLimit,
        DailyNoLimit,
        WeeklyNoLimit,
        MonthlyNoLimit
      } = this.model;

      let requestDaily = (RequestDailyTotalBetLimit || parseInt(RequestDailyTotalBetLimit) === 0) ? parseInt(RequestDailyTotalBetLimit) : null;
      let requestWeekly = (RequestWeeklyTotalBetLimit || parseInt(RequestWeeklyTotalBetLimit) === 0) ? parseInt(RequestWeeklyTotalBetLimit) : null;
      let requestMonthly = (RequestMonthlyTotalBetLimit || parseInt(RequestMonthlyTotalBetLimit) === 0) ? parseInt(RequestMonthlyTotalBetLimit) : null;

      requestDaily = DailyNoLimit ? null : requestDaily;
      requestWeekly = WeeklyNoLimit ? null : requestWeekly;
      requestMonthly = MonthlyNoLimit ? null : requestMonthly;

      const sameValue = CurrentDailyTotalBetLimit === requestDaily && CurrentWeeklyTotalBetLimit === requestWeekly && CurrentMonthlyTotalBetLimit === requestMonthly;
      const nullCheck = !requestDaily && requestDaily !== 0 && !requestWeekly && requestWeekly !== 0 && !requestMonthly && requestMonthly !== 0;

      return sameValue || nullCheck;
    },
  },
  methods: {
    async initialize() {
      this.current = {
        ...this.current,
        ...this.structure.current,
      };

      this.currencySymbol = getCurrencySymbol(this.current.Currency);
      this.rolling = this.structure.config.TotalBetLimitPeriodType;
    },

    createModalOptions() {
      const desc = this.structure.config.verifyFreeze ? 'responsibleGaming.modal.description.verifyFreeze' : 'responsibleGaming.modal.description.selfLimit';
      const descModel = {
        desc: desc,
        hangTime: 'responsibleGaming.modal.description.hangTimeLimit',
      };
      return {
        title: { default: this.$t('_.modal.title') },
        subTitle: { default: this.$t(`_.modal.subTitle.${this.structure.config.title}`) },
        modalType: 'HangTime',
        request: () => this.$rg.request(this.createPayload()),
        calculateList: this.calculateList,
        descModel,
        isVerifyFreeze: this.structure.config.verifyFreeze,
        config: this.structure.config
      };
    },

    createPayload() {
      const payload = { ...this.model };

      if (!this.model.DailyNoLimit && (this.current.CurrentDailyTotalBetLimit || this.current.CurrentDailyTotalBetLimit === 0) && !this.model.RequestDailyTotalBetLimit) {
        payload.RequestDailyTotalBetLimit = this.current.CurrentDailyTotalBetLimit;
      }
      if (!this.model.WeeklyNoLimit && (this.current.CurrentWeeklyTotalBetLimit || this.current.CurrentWeeklyTotalBetLimit === 0) && !this.model.RequestWeeklyTotalBetLimit) {
        payload.RequestWeeklyTotalBetLimit = this.current.CurrentWeeklyTotalBetLimit;
      }
      if (!this.model.MonthlyNoLimit && (this.current.CurrentMonthlyTotalBetLimit || this.current.CurrentMonthlyTotalBetLimit === 0) && !this.model.RequestMonthlyTotalBetLimit) {
        payload.RequestMonthlyTotalBetLimit = this.current.CurrentMonthlyTotalBetLimit;
      }

      return payload;
    },

    openModal() {
      this.$modal(ResponsibleProgressModal, this.createModalOptions());
    },

  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[total-bet-limit] {
  .desc { .pb(20);
    > p {.c(#999); .fs(12); line-height: 16px;}
  }
}

</style>