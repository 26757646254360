<template>
  <BaseModal my-device-remove-confirm :show-title="false" :class="{ hide: isHide }">
    <template #content>
      <div class="content-container">
        <template v-if="isAll">
          <p>{{ $t('_.allDeviceRemoveDescription1') }}</p>
          <p>{{ $t('_.allDeviceRemoveDescription2') }}</p>
        </template>
        <template v-else>
          <p v-html="$t('_.oneDeviceRemoveDescription1', { deviceName })" />
          <p>{{ $t('_.oneDeviceRemoveDescription2') }}</p>
        </template>
      </div>
    </template>
    <template #footer>
      <div class="modal-btn-holder">
        <GpButton type="line-green" @click="close">{{ $t('cancel') }}</GpButton>
        <GpButton type="submit-green" @click="remove">{{ $t('myInfo.myDevices.remove') }}</GpButton>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/views/components/gg-pass/modal/BaseModal.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import Specific from '@shared/types/Specific';
import MyDeviceRemoveResult from '@/views/components/pages/my-page/modal/MyDeviceRemoveResult.vue';
import { isEmpty } from 'lodash';
import { isExcludeGgpass } from '@/utils/baseUtil';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'MyDeviceRemoveConfirm',
  lexicon: 'myInfo.myDevices',
  props: {
    options: Specific,
  },
  components: { GpButton, BaseModal },
  data() {
    return {
      result: null,
      isHide: false,
    };
  },
  computed: {
    site: state('env', 'site'),
    isAll() {
      return this.options.deleteAll;
    },
    deviceName() {
      return this.options.deviceName;
    },
    isGgpass() {
      return !isExcludeGgpass(this.site);
    },
  },
  methods: {
    async remove() {
      if (isEmpty(this.options)) return;

      const { clientDeviceIdNew, deleteAll = false } = this.options;
      const result = this.isGgpass ? await this.$services.user.deleteOnepassTrustedDevice({ clientDeviceIdNew, deleteAll }) : await this.$services.user.deleteTrustedDeviceRequest({ clientDeviceIdNew, deleteAll });
      this.isHide = true;
      this.$modal(MyDeviceRemoveResult, { ...result });
      if (!result?.error) this.options.updateInfo();
    },
    close() {
      this.$modalCloseAll();
    },
  },
};
</script>

<style scoped lang="less">
@import '~@/less/proj.less';
[my-device-remove-confirm] {
  &.hide { .hide(); }
  .content-container {
    .mt(12); .c(#000); .tc(); line-height: 1.8;
  }
  .modal-btn-holder { .flex(); .mt(24); .gap(8); .fs(14); }
}
</style>
