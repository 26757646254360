<template>
  <div method-radio-item :class="[{'active' : isActive}]">
    <div class="radio-box">
      <div class="title-group">
        <div>
          <img v-if="hasIcon && iconType === 'png'" :src="`/img/${icon}`" alt="icon" />
          <FontIcon v-if="hasIcon && iconType === 'font'" :name="icon" />
          <h3>{{ label }}</h3>
        </div>
        <p v-if="isBadge">{{ $t('_.notRecommended') }}</p>
        <FontIcon v-if="isActive" class="active-mark" name="check-circle" />
        <GpButton v-if="!isAuthenticated" type="pill-line-green" size="sm" @click.stop="methodAuthentication">{{ $t('add') }}</GpButton>
      </div>
      <div class="content-group">
        <p v-if="isAuthenticated">{{ content }}</p>
      </div>
    </div>
  </div>

</template>

<script>
import Specific from '@shared/types/Specific';
import FontIcon from '@shared/components/common/FontIcon.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';

export default {
  name: 'SecurityMethodRadioItem',
  lexicon: 'myInfo',
  components: { GpButton, FontIcon },
  props: {
    value: { type: String },
    label: { type: String, default: null, },
    content: { type: String, default: null, },
    current: { type: String, default: '' },
    icon: { type: String, default: null },
    iconType: { type: String, default: null },
    isAuthenticated: { type: Boolean, default: false },
    isBadge: { type: Boolean, default: false, },
    active: { type: Boolean, default: false, },
    playerInfo: Specific,
  },
  computed: {
    isActive() {
      return this.active;
    },
    hasIcon() {
      return this.icon !== null;
    },
  },
  methods: {
    methodAuthentication() {
      this.$emit('open', this.value);
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[method-radio-item] { .c(black); .p(20); .mt(-1); .rel(); .min-h(60); .bgc(#fff); .br(16); .w(100%);
  &.active { .bgc(#F8FBF9);
    &::before { .br(16); .-a(@gp-green); content: ""; .w(100%); .h(100%); .abs(); .t(-1); .l(-1); .z(1); }
  }
  .radio-box { .flex(); .flex-dc(); .p(0); .gap(5);
    //@media (@ml-up) {
    //  .flex-dr();
    //  .space-between();
    //}
    .title-group { .flex(); .flex-dr(); .space-between(); .items-center();
      > div { .flex(); .flex-dr(); .items-center(); .gap(10);
        > img {.wh(18); object-fit: cover;}
        > i {.fs(16); .c(#737373); font-weight: 400;}
        > h3 {.fs(14); font-weight: 500;}
      }
      > p { .fs(12); .c(#ED4646);}
      .active-mark {.c(#2AAB3E);}
    }
    [my-info-button] { .w(); .ph(16); }
    .content-group { .flex(); .flex-dr(); .gap(10); .items-center();
      //@media (@ml-up) {
      //  .flex-row-reverse();
      //}
      > i {.fs(16); .c(@gp-green); font-weight: 400;}
      > p {.c(@gp-green); .fs(12); line-height: 16px; }
    }

  }
}
</style>