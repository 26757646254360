<template>
  <div receiver-sofort>
    <SofortFailed v-if="status !== sofortStatus.Finished" :result="result" :tryCount="tryCount" @click="clickTrigger" />
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import {sleep} from "@shared/utils/commonUtils.mjs";
import SofortController from '@/plugins/sofortController';
import SofortFailed from '@/views/components/pages/on-boarding/template/kyc/sofort/SofortFailed.vue';
import { sofortStatus } from '@/constants/base/onboarding/kyc';

export default {
  name: 'ReceiverSofort',
  components: { SofortFailed },
  data() {
    return {
      status: sofortStatus.Finished,
      result: null,
      tryCount: null,
      sofort: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    sofortStatus() {
      return sofortStatus;
    },
  },
  methods: {
    /**
     * 컨트롤러에서 결과 콜백
     * @param info
     */
    callback(info) {
      console.log('%csofort receiver callback', 'color:yellow', info);
      this.status = info.status;
      this.result = info.result;
      this.tryCount = info.tryCount ? Number(info.tryCount) : 0;
      if (this.status === sofortStatus.Processing) location.href = info.url;
      else if (this.status === sofortStatus.Finished) this.replaceRouteName('Replace');
    },
    clickTrigger(trigger) {
      if (trigger === 'replaceJumio') this.replaceRouteName('Jumio', { route: 'Jumio' });
      else if (trigger === 'personalDetail') this.replaceRouteName('ReCheckPersonalDetails', { route: 'ReCheckPersonalDetails' });
      else if (trigger === 'tryAgain') this.sofort.tryAgain(); // sofort url 조회 후 sofort 재시도
    }
  },
  async mounted() {
    const { $services: services, $router: router, site, lang, callback } = this;

    // this.$sentry.warning(`Receiver Sofort Url : ${location.href}`); // sentry logging 처리 주석
    this.sofort = new SofortController(this, { services, router, site, lang, callback });

    await sleep(60);
    await this.sofort.setQueriesInfo(location.search);
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[receiver-sofort] {}
</style>