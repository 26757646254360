<template>
  <BaseModal mfa-method-authentication-modal :title="title" @close="close">
    <template #content>
      <ValidationObserver v-slot="{invalid}">
        <div class="content">
          <template v-if="step === 'PersonalInfo'">
            <InputMobileNumber v-if="!isEmail" :model="model" :error="error" />
            <InputEmailAddress v-else :model="model" :error="error" />
            <GpDescription class="desc" :message="description" />
          </template>
          <template v-else>
            <VerifyResendButton :model-info="model" :structure="structure" theme="green" :api-type="model.Type" @complete="resendComplete" />
            <InputVerificationCode :model="model" :error="error" :length="verifyLength" />
            <GpDescription class="desc" :message="description" />
            <OpenEmailApp v-if="isEmail" :email="model.Email" />
          </template>
        </div>
        <GpButton type="submit-green" @click="confirm" :disabled="invalid">{{ buttonName }}</GpButton>
      </ValidationObserver>
    </template>
  </BaseModal>
</template>

<script>

import specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import { isExcludeGgpass } from '@/utils/baseUtil';
import BaseModal from '@/views/components/gg-pass/modal/BaseModal.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import InputMobileNumber from '@/views/components/pages/my-page/template/inputs/InputMobileNumber.vue';
import InputEmailAddress from '@/views/components/pages/my-page/template/inputs/InputEmailAddress.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import InputVerificationCode from '@/views/components/pages/my-page/template/inputs/InputVerificationCode.vue';
import VerifyResendButton from '@/views/components/common/VerifyResendButton.vue';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import ResendModal from '@/views/components/pages/my-page/modal/ResendModal.vue';
import OpenEmailApp from '@/views/components/common/OpenEmailApp.vue';

export default {
  name: 'MfaMethodAuthenticationModal',
  lexicon: 'myInfo',
  components: { OpenEmailApp, VerifyResendButton, InputVerificationCode, GpDescription, InputEmailAddress, InputMobileNumber, GpButton, BaseModal },
  props: {
    options: specific
  },
  data() {
    return {
      model: {
        CountryCode: null,
        CountryNumber: null,
        MobileNumber: null,
        Email: null,
        VerificationCode: null,
        SendVerificationType: 'MOBILE', // 이메일인지, 모바일인지 (EMAIL / MOBILE)
        ApiType: 'MFA_TYPE_ADD', // 어떤 목적으로 사용 할건지,
        Type: 'MFA_TYPE_ADD',
      },
      error: {
        CountryCode: null,
        MobileNumber: null,
        Email: null,
        VerificationCode: null,
      },
      structure: {
        limitTime: null,
      },
      step: 'PersonalInfo',
      hasError: false,
    };
  },
  computed: {
    site: state('env', 'site'),
    verifyLength() {
      if (!this.isGGPass) return 4;
      return 6;
    },
    isGGPass() {
      return !isExcludeGgpass(this.site);
    },
    type() {
      // type > EMAIL / MOBILE
      return this.options.type;
    },
    isEmail() {
      return this.model.SendVerificationType === 'EMAIL';
    },
    title() {
      return this.isEmail ? this.$t('myInfo.title.emailAuthentication') : this.$t('myInfo.title.mobileAuthentication');
    },
    buttonName() {
      return this.step === 'PersonalInfo' ? this.$t('next') : this.$t('verify');
    },
    description() {
      const method = this.isEmail ? this.$t('emailAddress') : this.$t('mobileNumber');
      if (this.step === 'PersonalInfo') {
        return this.$t('myInfo.mfa.setMfaDescription', { method });
      } else {
        const enterTheCode = this.$t('myInfo.verifyCode.enterTheCode', { length: this.verifyLength, method });
        const checkSpamFolder = this.$t('myInfo.verifyCode.checkSpamFolder');
        return !this.isEmail ? (enterTheCode + checkSpamFolder) : enterTheCode;
      }
    },
  },
  watch: {
    'model.MobileNumber': 'clearError',
    'model.Email': 'clearError',
    'model.CountryCode': 'clearError',
  },
  methods: {
    async confirm() {
      if(this.step === 'PersonalInfo') {
        const r = await this.sendVerification();
        if (this.$myinfo.errorHandler(r)) return;

        if(r?.error) {
          if(r.key === 'limit') this.structure.limitTime = r.value;
          else if (r.key === apiErrorCode.INVALD_PLAYER_KYC_STATUS) {
            await this.$myinfo.redirectKycUrl({ redirectPath: 'np-authentication', lang: this.$i18n('lang') });

            this.hasError = true;
            return;
          }
          else {
            if (this.isEmail) this.error.Email = this.$t(r.key);
            else this.error.MobileNumber = this.$t(r.key);

            this.hasError = true;
            return;
          }
        }

        this.step = 'VerifyCode';
      } else {
        const r = await this.verifyCode();
        if(r?.error) {
          this.error.VerificationCode = this.$t(r?.key);

          this.hasError = true;
          return;
        }

        this.$emit('resolve', true);
      }
    },

    close() {
      this.$modalCloseAll();
    },

    async sendVerification() {
      // API 호출
      const r = await this.$services.verification.sendVerification(this.model, this.model.Type);
      if(r?.error) {
        if(r?.key === apiErrorCode.REQUIRED_VERIFICATION_CODE) {
          return {...r, error: false};
        }
      }
      return r;
    },
    async verifyCode() {
      // Verify Api 호출
      return await this.$services.user.setLoginId(this.model);
    },

    errorHandler() {
      // 에러 에 따라 에러 메세지를 핸들링
    },

    clearError() {
      this.error.MobileNumber = null;
      this.error.Email = null;
      this.hasError = false;
    },

    async resendComplete(result) {
      const { isSend, error } = result;
      let message;

      if (!isSend) {
        if (error) {
          if (this.$myinfo.errorHandler(error)) return;

          const {key, value} = error;
          if (key === 'limit') message = this.$t(`${this.model.SendVerificationType}_RESEND_TERM_LIMITED`, { sec: `${value}s` });
          else if ([apiErrorCode.RESEND_LIMITED, apiErrorCode.MFA_RESEND_LIMITED, apiErrorCode.PHONE_RESEND_LIMITED, apiErrorCode.EMAIL_RESEND_LIMITED].includes(key)) message = this.$t('RESEND_COUNT_LIMIT', { method: this.isEmail ? this.$t('email') : this.$t('sms') });
          else message = this.$t(`${this.model.SendVerificationType}_RESEND_LIMITED`);
        }

        this.$modal(ResendModal, { message, success: false, error, allClose: false });
        return;
      }

      this.$modal(ResendModal, { message: this.$t('_.verifyCode.resendSuccess'), success: true, allClose: false });
      this.model.Token = result?.token;
    },
  },
  mounted() {
    this.model.SendVerificationType = this.options.SendVerificationType;
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[mfa-method-authentication-modal] {
  > .panel { .rel(); overflow: visible;
    .scroll-area {.h(calc(100% - 59px)); .flex(); .flex-dc(); overflow-y: visible;
      > .body { flex: 1; }
    }
  }
  .content {
    > [verify-resend] { .pt(8); .pb(20); .tr(); }
    .desc {
      > p { .mt(16); .fs(14); .c(#999); text-align: left; line-height: 1.5; }
    }
    .input-group { .flex();.flex-dc();.gap(8);.pt(25); }
    > [input-email-address] {
      [validation-errors] { .mt(4); }
    }
    > [input-verification-code] {
      .np-verify-code.six > span { .max-w(52); .max-h(52); }
      [validation-errors] { .w(100%); }
    }
    > [open-email-app] {
      > button { .ml(4); .c(@gp-green); .fs(14); }
      > [font-icon] { .fs(14); .c(@gp-green); }
      .c(@gp-green); font-weight: 500; .tc(); .pb(18)
    }
  }
}
</style>