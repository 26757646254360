import { render, staticRenderFns } from "./MyDeviceRemoveConfirm.vue?vue&type=template&id=165173d3&scoped=true"
import script from "./MyDeviceRemoveConfirm.vue?vue&type=script&lang=js"
export * from "./MyDeviceRemoveConfirm.vue?vue&type=script&lang=js"
import style0 from "./MyDeviceRemoveConfirm.vue?vue&type=style&index=0&id=165173d3&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "165173d3",
  null
  
)

export default component.exports