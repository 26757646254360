<template>
  <BaseModal mfa-change-level-verify-code-modal :title="title">
    <template #content>
      <ValidationObserver v-slot="{invalid}">
        <div class="content">
          <VerifyResendButton v-if="model.ApiType === 'MFA_TYPE_EDIT' && model.SendVerificationType !== ggpassMethod.GoogleAuthentication" :model-info="model" :structure="structure" theme="green" @complete="resendComplete" />
          <InputVerificationCode :model="model" :error="error" :length="verifyLength" />
          <GpDescription class="desc" :message="description" />
        </div>
        <GpButton type="submit-green" @click="confirm" :disabled="invalid || disabledButton">{{ $t('confirm') }}</GpButton>
      </ValidationObserver>
    </template>
  </BaseModal>
</template>

<script>

import specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import { isExcludeGgpass } from '@/utils/baseUtil';
import BaseModal from '@/views/components/gg-pass/modal/BaseModal.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import InputVerificationCode from '@/views/components/pages/my-page/template/inputs/InputVerificationCode.vue';
import VerifyResendButton from '@/views/components/common/VerifyResendButton.vue';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import ResendModal from '@/views/components/pages/my-page/modal/ResendModal.vue';
import { ggpassMethod } from '@/constants/base/my-page';

export default {
  name: 'MfaChangeLevelVerifyCodeModal',
  lexicon: 'myInfo',
  components: { GpDescription, VerifyResendButton, InputVerificationCode, GpButton, BaseModal },
  props: {
    options: specific
  },
  data() {
    return {
      verifyLength: 6,
      model: {
        VerificationCode: null,
        VerificationMethod: null,
        SecurityLevel: null,
        UsualLoginSecurity: null,
        UnusualLoginSecurity: null,
        SendVerificationType: 'EMAIL', // 이메일인지, 모바일인지
        Token: null,
        ApiType: null,
      },
      error: {
        VerificationCode: null,
      },
      structure: {
        limitTime: null,
      },
      hasError: false,
    };
  },
  computed: {
    site: state('env', 'site'),
    ggpassMethod() {
      return ggpassMethod;
    },
    isGGPass() {
      return !isExcludeGgpass(this.site);
    },
    info() {
      return this.options.info;
    },
    title() {
      return this.$t('myInfo.title.verificationNeeded');
    },
    isEmail() {
      return this.model.SendVerificationType === 'EMAIL';
    },
    description() {
      let mfaInfo;
      switch (this.model.SendVerificationType) {
        case 'EMAIL':
          mfaInfo = this.options.authInfo.Email;
          break;
        case 'MOBILE':
          mfaInfo = this.options.authInfo.MobileNumber;
          break;
        default:
          mfaInfo = this.$t('myInfo.googleAuthenticator');
          break;
      }

      return this.$t('myInfo.mfa.levelDownDescription', { method: mfaInfo });
    },
    disabledButton() {
      if(!this.model.VerificationCode) return true;
      return this.model.VerificationCode?.length < 6;
    },
  },
  watch: {
    'model.VerificationCode': 'clearError',
  },
  methods: {
    async confirm() {
      const r = await this.$services.mfa.sendMfa({...this.info, ...this.model});
      if(r?.error) {
        if (this.$myinfo.errorHandler(r)) return;

        this.hasError = true;
        this.error.VerificationCode = this.$t(r?.key);
        return;
      }
      this.$emit('resolve', true);
    },

    clearError() {
      this.hasError = false;
      this.error.VerificationCode = null;
    },

    async resendComplete(result) {
      const { isSend, error } = result;
      let message;

      if (!isSend) {
        if (error) {
          if (this.$myinfo.errorHandler(error)) return;

          const {key, value} = error;
          if (key === 'limit') message = this.$t(`${this.model.SendVerificationType}_RESEND_TERM_LIMITED`, { sec: `${value}s` });
          else if ([apiErrorCode.RESEND_LIMITED, apiErrorCode.MFA_RESEND_LIMITED, apiErrorCode.PHONE_RESEND_LIMITED, apiErrorCode.EMAIL_RESEND_LIMITED].includes(key)) message = this.$t('RESEND_COUNT_LIMIT', { method: this.isEmail ? this.$t('email') : this.$t('sms') });
          else message = this.$t(`${this.model.SendVerificationType}_RESEND_LIMITED`);
        }

        this.$modal(ResendModal, { message, success: false, error, allClose: false });
        return;
      }

      this.$modal(ResendModal, { message: this.$t('_.verifyCode.resendSuccess'), success: true, allClose: false });
      this.model.Token = result?.token;
    },
  },
  mounted() {
    this.structure.limitTime = this.options.limitTime;
    this.model.VerificationMethod = this.options.info.VerificationMethod;
    this.model.SecurityLevel = this.options.info.SecurityLevel;
    this.model.UsualLoginSecurity = this.options.info.UsualLoginSecurity;
    this.model.UnusualLoginSecurity = this.options.info.UnusualLoginSecurity;
    this.model.SendVerificationType = this.options.info.SendVerificationType;
    this.model.ApiType = 'MFA_TYPE_EDIT';
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[mfa-change-level-verify-code-modal] {
  > .panel { .rel(); overflow: visible;
    .scroll-area {.h(calc(100% - 59px)); .flex(); .flex-dc(); overflow-y: visible;
      > .body { flex: 1; }
    }
  }
  [validation-errors] { .w(100%); }
  .content { .max-w(420);
    > [verify-resend] { .pt(8); .pb(20); .tr(); }
    .desc {
      > p { .mt(20); .fs(12); .c(#999); text-align: left; line-height: 16px; }
    }
    .input-group { .flex();.flex-dc();.gap(20);.pt(25); }
  }
  @media (@ml-up) {
    > .panel.large { .max-w(586); .w(586); }
    .content { .max-w(546); }
  }
}
</style>