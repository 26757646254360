<template>
  <GpFormTemplate np-email-address>
    <template v-if="!isContactInfo" #header>
      <GpDescription :message="description" />
    </template>
    <template #content>
      <div v-if="model.Type">
        <InputEmailAddress :model="model" :is-verified="model.IsEmailVerified" :error="error" />
<!--        <CheckboxItem v-if="isChangeId && !isTwoAce" :class="['via-contact']" theme="light" :selected="model.IsContactUpdate" @change="updateWithContactInfo">{{ $t('_.mobileNumber.updateContactInfo') }}</CheckboxItem>-->
      </div>
    </template>
    <template #footer="{validate, invalid}">
      <GpButton type="submit-green" @click.prevent="confirm(validate)" :disabled="!!invalid || disableButton">{{ $t('_.send') }}</GpButton>
    </template>
  </GpFormTemplate>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { isExcludeGgpass } from '@/utils/baseUtil';
import InputEmailAddress from '@/views/components/pages/my-page/template/inputs/InputEmailAddress.vue';
import { siteIds } from '@/constants/base/siteMap.mjs';
import { routeNames } from '@/constants/model/my-page';
import npErrorHandler from '@/mixins/my-info/npErrorHandler';
import npStepInfo from '@/mixins/my-info/npStepInfo';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import Specific from '@shared/types/Specific';
import CheckboxItem from '@shared/components/common/input/CheckboxItem.vue';

export default {
  name: 'NpEmailAddress',
  components: { InputEmailAddress, GpButton, GpFormTemplate, GpDescription, CheckboxItem },
  lexicon: 'myInfo',
  mixins: [npErrorHandler, npStepInfo],
  props: {
    model: Specific,
    structure: { type: Specific, default: null },
  },
  computed: {
    routeNames: state('env', 'routeNames'),
    site: state('env', 'site'),
    onePassInfo: state('user', 'onePassInfo'),
    isChangeId() {
      return this.$route.name === routeNames.NpEmailAddress;
    },
    isContactInfo() {
      return this.$route.name === routeNames.NpContactEmailAddress;
    },
    isMfaSetting() {
      return this.$route.name === routeNames.NpMfaEmailAddress;
    },
    disableButton() {
      return this.error.Email !== null;
    },
    isTwoAce() {
      return this.site === siteIds.TWOACE;
    },
    isWSOP() {
      return this.site === siteIds.WSOPON;
    },
    isGGPDE() {
      return this.site === siteIds.GGPDE;
    },
    verificationType() {
      return this.isContactInfo ? 'ContactInfo' : 'LoginId';
    },
    kind() {
      return this.isContactInfo ? 'ContactInfo' : 'LoginId';
    },
    isGGpass() {
      return !isExcludeGgpass(this.site);
    },
    description() {
      const nonGgpassBrand = this.isWSOP ? 'GGPoker.ca' : this.brand;
      const brand = this.isGGpass ? 'GGPass' : nonGgpassBrand;
      if (this.isChangeId) return this.$t('_.emailAddress.description', { brand: brand });
      else if (this.isContactInfo) return null;
      else if (this.isMfaSetting) return this.$t('_.mfa.setMfaDescription', { method: this.$t('emailAddress') });
      else return '';
    },
  },
  watch: {
    'step': function () {
      this.error.Email = null;
      this.error.VerificationCode = null;
    }
  },
  methods: {
    async initialize() {
      const result = await this.$myinfo.fetch({payload: null, block: true});
      this.structure.title = this.$myinfo.title;
      this.model.LoginMethod = 'Email';
      this.model.SendVerificationType = 'EMAIL';
      this.setType();
      this.model.Email = result.Email;

      this.ggpassInitialize();
      this.npInitialize(result);
      this.mfaInitialize();
      this.contactInfoSetting(result);
      this.changeIdSetting(result);
    },

    ggpassInitialize() {
      if (!this.isGGpass) return;
      this.model.IsEmailVerified = this.onePassInfo.isEmailVerified;
    },

    npInitialize(result) {
      if (this.isGGpass) return;
      this.model.IsEmailVerified = result.IsEmailVerified;
    },

    changeIdSetting(result) {
      if (!this.isChangeId) return;
      const title = result?.Email ? 'myInfo.mobileNumber.changeLoginId' : 'myInfo.mobileNumber.registerLoginID';
      this.structure.title = title;
    },

    contactInfoSetting(result) {
      if (!this.isContactInfo) return;
      const { ContactInfo } = result;
      this.model.Email = ContactInfo.ContactEmail;
      this.model.IsEmailVerified = ContactInfo.IsContactEmailVerified;
    },

    mfaInitialize() {
      if (!this.isMfaSetting) return;
      const { VerificationMethod, SecurityLevel, UsualLoginSecurity, UnusualLoginSecurity } = this.model;
      if (!VerificationMethod || !SecurityLevel || !UsualLoginSecurity || !UnusualLoginSecurity) {
        this.replaceRouteName('NpLoginSecurity');
        return;
      }
    },

    setType() {
      const route = this.$route.name;
      switch (route) {
        case routeNames.NpContactEmailAddress:
          this.model.Type = 'CONTACT_INFO';
          this.model.ApiType = 'CONTACT_INFO';
          break;
        case routeNames.NpMfaEmailAddress:
          this.model.Type = 'MFA_TYPE_ADD';
          this.model.ApiType = 'MFA_TYPE_ADD';
          break;
        default:
          this.model.Type = 'CHANGE_LOGIN_ID';
          this.model.ApiType = 'CHANGE_LOGIN_ID';
          break;
      }
    },

    createPayload() {
      if (this.isContactInfo) return { ...this.model, VerificationType: this.verificationType, Kind: this.verificationType, VerificationCode : null, };
      return {...this.model, VerificationCode : null,};
    },

    updateWithContactInfo(v) {
      this.model.IsContactUpdate = v;
    },

    async confirm(validate) {
      const payload = this.createPayload();
      this.structure.resendInitTime = 0;
      const result = await this.$myinfo.request(payload, this.model.Type);

      if (result.error) {
        const errorResult = this.apiErrorHandler(result, true);
        if (errorResult?.key === 'limit') {
          this.structure.limitTime = +errorResult.value;
          return await this.routeName('NpVerifyCode');
        }
        return;
      }
      if (this.isGGpass && !this.isContactInfo) this.model.Token = result.token;
      await this.routeName('NpVerifyCode');
    },
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[np-email-address] { .pt(30);
  [gp-open-email-app] {.c(@gp-green); font-weight: 600;}
  [fp-resend-button] {
    .timer {.fs(#737373); .fs(12); }
    .resend {.c(@gp-green); .fs(14); .pb(20); text-decoration-line: none; font-weight: 600;
      &.end {.c(@gp-green);}
    }
  }
  .via-contact { .mt(10); .c(black); .fs(14); line-height: 16px;
    &.disable { .c(#757575);
      > i { .-a(#EEEEEE); .bgc(#757575);
        > svg {fill: white;}
      }
    }
  }
}

</style>