import StoreProxy from '@shared/modules/StoreProxy';
import ToastProxy from '@shared/modules/ToastProxy';
import ModalProxy from '@shared/modules/ModalProxy';
import CookieService from '@/services/CookieService';
import BaseService from '@/services/BaseService';
import SignService from '@/services/sign/SignService';
import CaptchaService from '@/services/CaptchaService';
import OnBoardingService from '@/services/on-boarding/OnboardingService';
import KycService from '@/services/on-boarding/KycService';
import AccountService from '@/services/AccountService';
import GgPassService from '@/services/ggpass/GgPassService';
import MfaService from '@/services/MfaService';
import TokenService from '@/services/sign/TokenService';
import NoticeService from '@/services/NoticeService';
import FindIdService from '@/services/find-id/findIdService';
import ApiConnection from '@/modules/ApiConnection';
import PromotionService from '@/services/promotion/PromotionService';
import VerificationService from '@/services/VerificationService';
import UserService from '@/services/UserService';
import { brandIds, siteIds } from '@/constants/base/siteMap';
import { getMainDomain, parseQuery } from '@shared/utils/urlUtils.mjs';
import { getCookie } from '@shared/utils/cookieUtils.mjs';

export class Service {
  static cnt = 0;

  id;
  /** @type {StoreProxy} */ store;
  /** @type {ToastProxy} */ toast;
  /** @type {ModalProxy} */ modal;
  /** @type {CookieService} */ cookie;

  /** API */
  #npDevApi;

  #proxyApi;
  #proxyNpApi;
  #proxyNpPublicApi;
  #proxyNpUserApi;
  #proxyNpPromoApi;
  #proxyNpTpmApi;
  #proxyNpChannelingApi;
  #proxyOpApi;
  #proxyAuthOpApi;

  constructor({ store = null, cookies = null }) {
    this.id = Service.cnt++;
    this.store = new StoreProxy(store);
    this.toast = new ToastProxy();
    this.modal = new ModalProxy();
    this.cookie = new CookieService(cookies);
  }

  #constructors = {
    base: BaseService,
    sign: SignService,
    captcha: CaptchaService,
    onboarding: OnBoardingService,
    kyc: KycService,
    account: AccountService,
    ggpass: GgPassService,
    mfa: MfaService,
    token: TokenService,
    notice: NoticeService,
    findId: FindIdService,
    promotion: PromotionService,
    verification: VerificationService,
    user: UserService,
  };

  #instances = {};

  get brandPath() {
    const brand = this.store.state.env.brand;
    const site = this.store.state.env.site;
    let brandPath = brand;
    switch (brand) {
      case brandIds.GGPOKERUK: {
        brandPath = 'GGPOKER';
        break;
      }
      case brandIds.GGPUKE:
      case brandIds.EVPUKE: {
        brandPath = 'MBP';
        break;
      }
    }
    if (this.store.state.env.isCasino) {
      brandPath = site === siteIds.WSOPON ? brandIds.WSOPCASINO : brandIds.GGPCOMCASINO;
    }
    return brandPath;
  }

  #npBrandPath(type, brand = true) {
    let path = `/np-gw/${type}`;
    if (brand) path += `/${this.brandPath}`;
    return path;
  }
  get #npDevEndPoint() { return this.store.state.env.api; }
  get #proxyUrl() {
    if (process.env.VUE_APP_ENV === 'local') return process.env[`VUE_APP_PROXY_URL_${this.store.state.env.site}`];

    const domain = process.env[`VUE_APP_PROXY_SUB_DOMAIN_${this.store.state.env.site}`];
    return `https://${domain}.${getMainDomain(location.hostname)}`;
  }
  get base() { return this.getService('base'); }
  get sign() { return this.getService('sign'); }
  get token() { return this.getService('token'); }
  get captcha() { return this.getService('captcha'); }

  get onboarding() { return this.getService('onboarding'); }
  get kyc() { return this.getService('kyc'); }
  get poi() { return this.getService('poi'); }
  get poa() { return this.getService('poa'); }
  get verify() { return this.getService('verify'); }
  get account() { return this.getService('account'); }

  get ggpass() { return this.getService('ggpass'); }
  get mfa() { return this.getService('mfa'); }
  get notice() { return this.getService('notice'); }
  get findId() { return this.getService('findId'); }
  get verification() { return this.getService('verification'); }
  get user() { return this.getService('user'); }
  get promotion() { return this.getService('promotion'); }

  get npDevApi() {
    if (!this.#npDevApi) this.#npDevApi = new ApiConnection(this.#npDevEndPoint, this, this.applyBase.bind(this));
    return this.#npDevApi;
  }

  get proxyApi() {
    if (!this.#proxyApi) this.#proxyApi = new ApiConnection(`${this.#proxyUrl}`, this, this.applyBase.bind(this), () => {}, true);
    return this.#proxyApi;
  }

  get proxyOpApi() {
    if (!this.#proxyOpApi) this.#proxyOpApi = new ApiConnection(`${this.#proxyUrl}/proxy/not-auth`, this, this.applyBase.bind(this), () => ({path: ''}), true);
    return this.#proxyOpApi;
  }

  get proxyAuthOpApi() {
    if (!this.#proxyAuthOpApi) this.#proxyAuthOpApi = new ApiConnection(`${this.#proxyUrl}/proxy/auth`, this, this.applyBase.bind(this), () => ({path: ''}), true);
    return this.#proxyAuthOpApi;
  }

  get proxyNpApi() {
    if (!this.#proxyNpApi) this.#proxyNpApi = new ApiConnection(`${this.#proxyUrl}/proxy/not-auth`, this, this.applyBase.bind(this), () => ({path: this.#npBrandPath('', false)}), true);
    return this.#proxyNpApi;
  }

  get proxyNpPublicApi() {
    if (!this.#proxyNpPublicApi) this.#proxyNpPublicApi = new ApiConnection(`${this.#proxyUrl}/proxy/not-auth`, this, this.applyBase.bind(this), () => ({path: this.#npBrandPath('public')}), true);
    return this.#proxyNpPublicApi;
  }

  get proxyNpChannelingApi() {
    if (!this.#proxyNpChannelingApi) this.#proxyNpChannelingApi = new ApiConnection(`${this.#proxyUrl}/proxy/not-auth`, this, this.applyBase.bind(this), () => ({path: this.#npBrandPath('channeling', false)}), true);
    return this.#proxyNpChannelingApi;
  }

  get proxyNpUserApi() {
    if (!this.#proxyNpUserApi) this.#proxyNpUserApi = new ApiConnection(`${this.#proxyUrl}/proxy/auth`, this, this.applyBase.bind(this), () => ({path: this.#npBrandPath('user')}), true);
    return this.#proxyNpUserApi;
  }

  get proxyNpPromoApi() {
    if (!this.#proxyNpPromoApi) this.#proxyNpPromoApi = new ApiConnection(`${this.#proxyUrl}/proxy/auth`, this, this.applyBase.bind(this), () => ({path: this.#npBrandPath('promo')}), true);
    return this.#proxyNpPromoApi;
  }

  get proxyNpTpmApi() {
    const site = this.store.state.env.site;
    if (!this.#proxyNpTpmApi) this.#proxyNpTpmApi = new ApiConnection(`${this.#proxyUrl}/proxy/auth`, this, this.applyBase.bind(this), () => ({path: this.#npBrandPath(site === siteIds.WSOPON ? 'wsop-tpm' : 'tpm', false)}), true);
    return this.#proxyNpTpmApi;
  }

  applyGgpass(config) {
    const c = config || {};
    if (!c.headers) c.headers = {};
    return c;
  }

  applyBase(config) {
    const c = config || {};
    if (!c.headers) c.headers = {};
    if (typeof window !== 'undefined') {
      const queries = parseQuery(location.search);
      const isFromClientApp = getCookie('isFromClientApp');
      const isFromMobileApp = getCookie('isFromMobileApp');
      const isDeskTop = (queries?.isFromClientApp === 'true' || isFromClientApp || !this.store?.state?.browser?.mobile);
      const isMobile = (queries?.isFromMobileApp === 'true' || isFromMobileApp || this.store?.state?.browser?.mobile);
      c.headers['Fe-Referer-Full-Path'] = location.href;
      c.headers['Fe-User-Agent-Device'] = isDeskTop ? 'Desktop' : isMobile ? 'Mobile' : null;
    }
    return c;
  }

  getService(name) {
    if (!this.#instances[name]) this.#instances[name] = new this.#constructors[name](this);
    return this.#instances[name];
  }
}
