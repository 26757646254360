<template>
  <GpFormTemplate login-security>
    <template #content>
      <div class="security-level-group">
        <GpTitle :name="securityLabel" />
        <InputSecurityLevel :model="model" :current-mfa="currentMfa" @initMethod="initMethod" />
      </div>
      <div v-if="isSecuritySelected" class="security-method-group">
        <GpTitle v-if="isSecuritySelected" :name="methodLabel" />
        <InputSecurityMethod :model="model" :authInfo="authInfo" :userInfo="userInfo" :current-mfa="currentMfa" @auth="setAuthentication" />
      </div>
    </template>
    <template #footer="{validate}">
      <GpButton type="submit-green" @click="submit(validate)" :progress="!isLoad" :disabled="buttonDisabled">{{ $t('confirm') }}</GpButton>
    </template>
  </GpFormTemplate>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { isExcludeGgpass } from '@/utils/baseUtil';
import { ggpassMethod, mfaLevel, mfaMethod, STEP } from '@/constants/base/my-page';
import SecurityLevelRadioItem from '@/views/components/pages/my-page/template/login-securty/SecurityLevelRadioItem.vue';
import SecurityMethodRadioItem from '@/views/components/pages/my-page/template/login-securty/SecurityMethodRadioItem.vue';
import GpTitle from '@/views/components/gg-pass/GpTitle.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import Specific from '@shared/types/Specific';
import InputSecurityLevel from '@/views/components/pages/my-page/template/inputs/InputSecurityLevel.vue';
import InputSecurityMethod from '@/views/components/pages/my-page/template/inputs/InputSecurityMethod.vue';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import MfaChangeLevelVerifyCodeModal from '@/views/components/pages/my-page/modal/login-security/MfaChangeLevelVerifyCodeModal.vue';

export default {
  name: 'NpLoginSecurity',
  lexicon: 'myInfo',
  components: { InputSecurityMethod, InputSecurityLevel, GpTitle, GpButton, GpFormTemplate },
  props: {
    structure: { type: Specific, default: null },
    model: Specific,
  },
  data() {
    return {
      errorMsg: null,
      isVerifyGoogleAuthentication: false,
      mfa: null,
      currentMfa: null,
      authInfo: {
        EmailAuthenticated: false,
        Email: '',
        MobileAuthenticated: false,
        MobileNumber: '',
        GoogleAuthenticated: false,
        Google: '',
      },
      isLoad: false,
      SecurityLevelRadioItem,
      SecurityMethodRadioItem
    };
  },
  computed: {
    site: state('env', 'site'),
    userInfo: state('user', 'userInfo'),
    onePassInfo: state('user', 'onePassInfo'),
    csEmail: state('env', 'csEmail'),
    methodLabel() {
      const key = this.model.SecurityLevel === mfaLevel.LOW ? '_.mfa.notificationMethod' : '_.mfa.mfaAuthentication';
      return this.$t(key);
    },
    securityLabel() {
      return this.$t('_.mfa.securityLevel');
    },
    buttonDisabled() {
      if (!this.currentMfa) return true;
      if (this.model.SecurityLevel === this.currentMfa.SecurityLevel && this.model.VerificationMethod === this.currentMfa.VerificationMethod) {
        return true;
      }
      return false;
    },
    isSecuritySelected() {
      return this.model.SecurityLevel !== mfaLevel.OFF;
    },
    isGGPass() {
      return !isExcludeGgpass(this.site);
    }
  },
  methods: {
    async initialize() {
      this.$emit('changeStep', STEP.DEFAULT);
      const mfaConfig = await this.$myinfo.fetch({ payload: null, block: true });
      this.mfa = mfaConfig;
      if(!this.isGGPass) {
        this.currentMfa = mfaConfig;
        this.npInitialize();
      }
      else {
        this.currentMfa = {};
        this.ggpassInitialize();
      }
    },
    ggpassInitialize() {
      if (!this.isGGPass) return;
      const mfa = this.mfa.find(m => m.isEnabled);

      if (!mfa) {
        this.model.SecurityLevel = mfaLevel.OFF;
        this.model.VerificationMethod = null;
      } else {
        this.model.SecurityLevel = mfaLevel[mfa.level];
        this.model.VerificationMethod = mfaMethod[mfa.methodType];
      }

      this.currentMfa.SecurityLevel = this.model.SecurityLevel;
      this.currentMfa.VerificationMethod = this.model.VerificationMethod;

      this.authInfoInitialize();
    },
    npInitialize() {
      if (this.isGGPass) return;
      this.model.SecurityLevel = this.mfa.SecurityLevel;
      this.model.VerificationMethod = this.mfa.VerificationMethod;
      this.model.UsualLoginSecurity = this.mfa.UsualLoginSecurity;
      this.model.UnusualLoginSecurity = this.mfa.UnusualLoginSecurity;

      this.authInfoInitialize();
    },
    authInfoInitialize() {
      if (this.isGGPass) {
        const email = this.mfa.find(m => m.methodType === 'EMAIL');
        const mobile = this.mfa.find(m => m.methodType === 'MOBILE');
        const google = this.mfa.find(m => m.methodType === 'GOOGLE');

        this.authInfo = {
          ...this.authInfo,
          EmailAuthenticated: email ? email.isVerified : false,
          Email: email ? email?.methodValue : null,
          MobileAuthenticated: mobile ? mobile.isVerified : false,
          MobileNumber: mobile ? mobile?.methodValue : null,
          GoogleAuthenticated: google ? google.isVerified : false,
          Google: this.$t('_.verified'),
          // Google: google ? google?.methodValue : null,
        };
      } else {
        this.authInfo = {
          ...this.authInfo,
          EmailAuthenticated: this.userInfo.IsEmailVerified,
          Email: this.userInfo.Email ? this.userInfo.Email : null,
          MobileAuthenticated: this.userInfo.IsMobileVerified,
          MobileNumber: (this.userInfo?.MobileCountryCode && this.userInfo?.MobileNumber) ? `+${this.userInfo?.MobileCountryCode}${this.userInfo?.MobileNumber}` : null,
          GoogleAuthenticated: this.mfa.IsGoogleAuthenticated || false,
          Google: this.$t('_.verified'),
        };
      }
    },
    initMethod() {
      if(this.model.SecurityLevel === this.currentMfa?.SecurityLevel) {
        this.model.VerificationMethod = this.currentMfa?.VerificationMethod;
      } else this.model.VerificationMethod = this.userInfo.IsEmailVerified ? mfaMethod.EMAIL : mfaMethod.MOBILE;
    },
    async setAuthentication(type) {
      await this.$myinfo.setPlayerInfo();
      const mfaConfig = await this.$myinfo.fetch({ payload: null, block: true });
      this.mfa = mfaConfig;

      this.authInfoInitialize();

      switch (type) {
        case 'MOBILE':
          this.setMobileAuthentication();
          break;
        case 'EMAIL':
          this.setEmailAuthentication();
          break;
        case 'GOOGLE':
          this.setGoogleVerifyAuthentication();
          break;
        default:
          break;
      }
    },
    setMobileAuthentication() {
      this.authInfo.MobileAuthenticated = true;
      this.$modalCloseAll();
    },
    setEmailAuthentication() {
      this.authInfo.EmailAuthenticated = true;
      this.$modalCloseAll();
    },
    setGoogleVerifyAuthentication() {
      this.isVerifyGoogleAuthentication = true;
      this.authInfo.GoogleAuthenticated = true;

      this.$modalCloseAll();
    },
    async submit(validate) {
      scrollTo(0, 0);
      this.model.SendVerificationType = ggpassMethod[this.currentMfa.VerificationMethod];
      const r = await this.$myinfo.request(this.model, null, false);

      if (r?.error) {
        let modalResult = false;
        if ((r?.key === 'limit') // RESEND_TERM_LIMITED 대응
          || (r?.key === apiErrorCode.REQUIRED_MFA_CODE) // OP 대응
          || ([apiErrorCode.SEND_MFA_CODE, apiErrorCode.REQUIRED_EMAIL_VERIFICATION, apiErrorCode.REQUIRED_MOBILE_VERIFICATION, apiErrorCode.REQUIRED_GOOGLE_VERIFICATION].includes(r?.key)) // NP 대응
        ) {
          modalResult = await this.$modal(MfaChangeLevelVerifyCodeModal, { limitTime: r?.value, info: this.model, authInfo: this.authInfo });
        }
        else if (r?.key === apiErrorCode.FAILED_MFA_VERIFICATION) r.key = apiErrorCode.INTERNAL_ERROR;
        else if (r?.key === apiErrorCode.ALREADY_USED_MFA_CODE) r.key = apiErrorCode.INVALID_MFA_CODE;

        if(!modalResult) {
          if ([apiErrorCode.RESEND_LIMITED, apiErrorCode.MFA_RESEND_LIMITED, apiErrorCode.PHONE_RESEND_LIMITED, apiErrorCode.EMAIL_RESEND_LIMITED].includes(r?.key)) r.key = 'RESEND_COUNT_LIMIT';
          this.$emit('error', r?.key, { csEmail: this.csEmail, method: this.model.SendVerificationType === 'EMAIL' ? this.$t('email') : this.model.SendVerificationType === 'MOBILE' ? this.$t('sms') : this.$t('_.googleAuthenticator') });
          return;
        }
      }

      await this.$myinfo.setPlayerInfo();
      this.$emit('success', 'myInfo.mfaSuccess');
    },
  },
  async mounted() {
    this.structure.title = this.$myinfo.title;
    await this.initialize();
    this.$nextTick(() => {
      this.isLoad = true;
    });
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[login-security] {
  .my-title-label { .w(100%); }
  .desc { .flex(); .items-center();
    > p {.c(black);}
  }
  .logout-device {.flex(); .flex-dc();
    > .item {.flex();.space-between(); .bgc(white); .br(16); .p(16, 20, 16, 20); .items-center();
      > label {flex: 1; .c(black); .fs(14); font-weight: 500;}
      [my-info-button] {.br(100); .w(auto); .h(30); line-height: 16px !important;
      }
    }
  }
  .security-level-group {
    > p {.c(black); .bold(); .pv(10);}
  }
  .security-method-group { .pt(5); .pb(20);
    > p {.c(black); .bold(); .pv(10);}
  }
  @media (@ml-up) {
    .flex(); .justify-start(); .gap(10);
    > div + div { .mt(0); }
  }
}
</style>
