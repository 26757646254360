import { apiErrorCode, apiErrorType } from '@/constants/base/apiErrorCode';
import { siteIds } from '@/constants/base/siteMap.mjs';
export const errorInfo = () => ({
  [apiErrorCode.ACCOUNT_SUSPENDED]: accountSuspended(),
  [apiErrorCode.ACCESS_DENIED]: accessDenied(),
  [apiErrorCode.EXPIRED_TOKEN]: accessDenied(),
  [apiErrorCode.ACCOUNT_LOCKED]: accountLocked(),
  [apiErrorCode.ACCOUNT_UNVERIFIED]: null,
  [apiErrorCode.ALREADY_VERIFIED_MOBILE]: alreadyVerifiedMobile(),
  [apiErrorCode.EMAIL_EXISTS]: emailExists(),
  [apiErrorCode.FAILED_SEND_EMAIL_VERIFICATION]: failedSendEmailVerification(),
  [apiErrorCode.INVALID_REQUEST]: invalidRequest(),
  [apiErrorCode.INVALID_PLAYER]: invalidPlayer(),
  [apiErrorCode.INVALID_PLACEOFBIRTH]: { [apiErrorCode.INVALID_PLACE_OF_BIRTH]: null, },
  [apiErrorCode.INVALID_POSTALCODE]: invalidPostalCode(),
  [apiErrorCode.INVALID_SWIFTBIC]: { [apiErrorCode.INVALID_SWIFTBIC]: null, },
  [apiErrorCode.INVALID_FUND_PASSWORD]: null,
  [apiErrorCode.INVALID_TRANSACTION_AMOUNT]: invalidTransactionAmount(),
  [apiErrorCode.INVALID_PASSWORD_VERIFICATION]: null,
  [apiErrorCode.INVALID_MOBILE_NUMBER]: null,
  [apiErrorCode.INVALID_VERIFICATIONCODE]: null,
  [apiErrorCode.INVALID_BONUSCODE]: null,
  [apiErrorCode.INVALID_BONUS_CODE]: null,
  [apiErrorCode.INVALID_AGENT]: invalidAgent(),
  [apiErrorCode.INVALID_EMAIL]: { [apiErrorCode.INVALID_EMAIL]: null, },
  [apiErrorCode.INVALID_STATE]: { [apiErrorCode.INVALID_STATE]: null, },
  [apiErrorCode.INVALID_EMAIL_VERIFICATIONCODE]: null,
  [apiErrorCode.INVALID_LOGIN_PASSWORD]: null,
  [apiErrorCode.INVALID_PASSWORD_LENGTH]: { [apiErrorCode.INVALID_PASSWORD_LENGTH]: null, },
  [apiErrorCode.INVALID_PASSWORD_LETTER]: { [apiErrorCode.INVALID_PASSWORD_LETTER]: null, },
  [apiErrorCode.INVALID_PASSWORD_PARTS_EMAIL]: { [apiErrorCode.INVALID_PASSWORD_PARTS_EMAIL]: null, },
  [apiErrorCode.INVALID_PASSWORD_PARTS_MOBILENUMBER]: null,
  [apiErrorCode.INVALID_PASSWORD_PARTS_USERNAME]: { [apiErrorCode.INVALID_PASSWORD_PARTS_USERNAME]: null, },
  [apiErrorCode.INVALID_PASSWORD_PARTS_NAME]: { [apiErrorCode.INVALID_PASSWORD_PARTS_NAME]: null, },
  [apiErrorCode.INVALID_PASSWORD_ALPHABET_NUMERIC_RULE]: null,
  [apiErrorCode.INVALID_DAY1_DEPOSIT_LIMIT]: null,
  [apiErrorCode.INVALID_DAY7_DEPOSIT_LIMIT]: null,
  [apiErrorCode.LOSS_LIMIT_NUMBER_OF_CHANGES_EXCEEDED]: null,
  [apiErrorCode.MOBILE_NOT_FOUND]: null,
  [apiErrorCode.NOT_ENOUGH_BALANCE]: notEnoughBalance(),
  [apiErrorCode.SUMSUB_INVALID_REQUEST]: { [apiErrorCode.INVALID_DOCUMENT]: null, },
  [apiErrorCode.VERIFICATIONTOKEN_NOT_FOUND]: null,
  [apiErrorCode.RESPONSIBLE_GAMING]: responsibleGaming(),
  [apiErrorCode.AISG_REGISTRATION_INVALID_INFORMATION]: aisgRegistrationInvalidInformation(),
  [apiErrorCode.AISG_REGISTRATION_INTERNAL_ERROR]: aisgRegistrationInternalError(),
  [apiErrorCode.AISG_REGISTRATION_INVALID_OPERATION]: aisgRegistrationInvalidOperation(),
  [apiErrorCode.AISG_REGISTRATION_NOT_VERIFIED]: aisgRegistrationNotVerified(),
  [apiErrorCode.AISG_REGISTRATION_INVALID_HID]: aisgRegistrationInvalidHid(),
  [apiErrorCode.EXCLUDEDE_BYSERVICE]: excludedByService(),
  [apiErrorCode.INTERNAL_ERROR]: internalError(), // with close, 새로고침 시 화면 유지. step이 다르거나 예외상황 발생 시 활용되는듯. TransUnion일때는 좀 더 명확해져야할듯
  [apiErrorCode.USER_INFO_NOT_VERIFIED]: userInfoNotVerified(),
  [apiErrorCode.USER_INFO_ALREADY_EXIST]: userInfoAlreadyExist(),
  [apiErrorCode.ACCOUNT_LOCKED_SCREENING_CHECK]: userInfoCheck(),
  [apiErrorCode.INVALID_TOKEN]: accessDenied(),
  [apiErrorCode.AUTH_TOKEN_EXPIRED]: accessDenied(),
});

const accountSuspended = () => ({
  [apiErrorCode.ACCOUNT_SUSPENDED]: {
    [apiErrorType.VERIFICATION]: {
      default: { title: 'errorPages.verificationFailed.title', content: 'errorPages.accountSuspended.verificationSuspended', type: 'onboarding', btnName: 'close', btnAction: 'close' },
    },
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.selfExcluded.title', dearCustomer: 'errorPages.index.dearCustomer', subTitle: 'errorPages.accountLocked.accountLocked', otherSelfExclusion: 'errorPages.excluded.otherSelfExclusion', description: 'errorPages.excluded.description', logout: false }
    }
  },
  [apiErrorCode.ACCOUNT_LOGIN_ATTEMPS_FAILED_LOCKED]: null, // backend에서 삭제된 것으로 보임
  [apiErrorCode.ACCOUNT_LOGIN_REMAIN_LIMIT]: null, // backend에서 삭제된 것으로 보임
  [apiErrorCode.ACCOUNT_LOGIN_REMAIN_LIMIT_OVER_LOCKED]: null, // backend에서 삭제된 것으로 보임
  [apiErrorCode.CLOSED]: {
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.accountLocked.title', dearCustomer: 'errorPages.accountLocked.dearCustomer', content: 'errorPages.accountLocked.closed', description: 'errorPages.accountLocked.description', logout: true }
    }
  },
  [apiErrorCode.COMPLIANCE_CHECK]: {
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.accountSuspended.title', dearCustomer: 'errorPages.accountSuspended.dearCustomer', content: 'errorPages.accountSuspended.suspendedOthers', description: 'errorPages.accountSuspended.description', logout: true }
    },
  },
  [apiErrorCode.COMPLIANCE_CHECK_PERIOD]: {
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.accountSuspended.title', dearCustomer: 'errorPages.accountSuspended.dearCustomer', content: 'errorPages.accountSuspended.suspendedOthers', description: 'errorPages.accountSuspended.description', logout: true }
    },
  },
  [apiErrorCode.SECURITY]: {
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.accountSuspended.title', dearCustomer: 'errorPages.accountSuspended.dearCustomer', content: 'errorPages.accountSuspended.suspendedOthers', description: 'errorPages.accountSuspended.description', logout: true }
    }
  },
  [apiErrorCode.MULTIACC_CHECK]: {
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.accountSuspended.title', dearCustomer: 'errorPages.accountSuspended.dearCustomer', content: 'errorPages.accountSuspended.suspendedOthers', description: 'errorPages.accountSuspended.description', logout: true }
    }
  },
  [apiErrorCode.EXCLUSION_CHECK]: {
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.selfExcluded.title', dearCustomer: 'errorPages.selfExcluded.dearCustomer', cruksContent: 'errorPages.selfExcluded.cruksContent', content: 'errorPages.selfExcluded.content', description: 'errorPages.selfExcluded.description', logout: true }
    }
  },
  [apiErrorCode.BLOCKEDLISTACC_CHECK]: {
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.accountLocked.title', dearCustomer: 'errorPages.accountLocked.dearCustomer', content: 'errorPages.accountLocked.blockedList', description: 'errorPages.accountLocked.description', logout: true }
    }
  },
  // [apiErrorCode.EXCEED_LOGIN_ATTEMPTS]: null,
  // [apiErrorCode.LOCK_BALANCE]: null,
  // [apiErrorCode.PANIC_FROM_USER]: null,
  // [apiErrorCode.SECURITY_BLOCKED]: null,
  // [apiErrorCode.DELETED_ACCOUNT]: null,
  [apiErrorCode.ETC]: { // ACCOUNT_LOGIN_ATTEMPS_FAILED_LOCKED, ACCOUNT_LOGIN_REMAIN_LIMIT, ACCOUNT_LOGIN_REMAIN_LIMIT_OVER_LOCKED, COMPLIANCE_CHECK_PERIOD, CLOSED, COMPLIANCE_CHECK, SECURITY, MULTIACC_CHECK, EXCLUSION_CHECK, BLOCKEDLISTACC_CHECK 이 아닐 경우
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.index.title', dearCustomer: 'errorPages.index.dearCustomer', subTitle: 'errorPages.index.subTitle', permanentSelfExclusion: 'errorPages.index.permanentSelfExclusion', otherSelfExclusion: 'errorPages.index.otherSelfExclusion', description: 'errorPages.index.description', logout: true }
    }
  },
});

const accessDenied = () => ({
  [apiErrorCode.ACCESS_DENIED]: {
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.index.title', dearCustomer: 'errorPages.index.dearCustomer', description: 'errorPages.index.description', logout: true }
    }
  },
  [apiErrorCode.ACCESS_DENIED_AGENT_HAS_NO_PERMISSION]: {
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.index.title', dearCustomer: 'errorPages.index.dearCustomer', description: 'errorPages.index.description', logout: true }
    }
  },
  [apiErrorCode.ACCESS_DENIED_UNRECOGNIZED_SERVICE_MODE]: {
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.index.title', dearCustomer: 'errorPages.index.dearCustomer', description: 'errorPages.index.description', logout: true }
    }
  },
  [apiErrorCode.INVALID_PLAYER]: {
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.index.title', dearCustomer: 'errorPages.index.dearCustomer', description: 'errorPages.index.description', logout: true }
    }
  },
  [apiErrorCode.REQUIRE_CHANGE_PASSWORD]: {
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.index.title', dearCustomer: 'errorPages.index.dearCustomer', description: 'errorPages.index.description', logout: true }
    }
  },
  [apiErrorCode.INVALID_REFERENCE_KEY]: {
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.index.title', dearCustomer: 'errorPages.index.dearCustomer', description: 'errorPages.index.invalidRefKeyDescription', logout: true }
    }
  },
  [apiErrorCode.EXPIRED_TOKEN]: {
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.index.title', dearCustomer: 'errorPages.index.dearCustomer', description: 'errorPages.index.description', logout: true }
    }
  },
  [apiErrorCode.INVALID_TOKEN]: {
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.index.title', dearCustomer: 'errorPages.index.dearCustomer', description: 'errorPages.index.invalidToken', logout: true }
    }
  },
  [apiErrorCode.AUTH_TOKEN_EXPIRED]: {
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.index.title', dearCustomer: 'errorPages.index.dearCustomer', description: 'errorPages.index.invalidToken', logout: true }
    }
  },
});

const accountLocked = () => ({
  [apiErrorCode.ACCOUNT_LOCKED]: { // 이거.. customCode가 아무 값도 없어서 code가 들어간 것으로 로직이 보임.. 88agent>httpInterceptor.js
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.accountLocked.title', dearCustomer: 'errorPages.accountLocked.dearCustomer', content: 'errorPages.accountLocked.accountLocked', description: 'errorPages.accountLocked.description', logout: true }
    }
  },
  [apiErrorCode.ACCOUNT_LOGIN_LIMIT_ACCESS]: {
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.loginlimit.title', dearCustomer: 'errorPages.loginlimit.dearCustomer', description: 'errorPages.loginlimit.description', logout: true }
    }
  },
  [apiErrorCode.AISG_SELF_EXCLUDED]: {
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.selfExcluded.title', dearCustomer: 'errorPages.selfExcluded.dearCustomer', cruksContent: 'errorPages.selfExcluded.cruksContent', content: 'errorPages.selfExcluded.content', description: 'errorPages.selfExcluded.description', logout: true }
    }
  },
  [apiErrorCode.CRUKS_SELF_EXCLUDED]: {
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.selfExcluded.title', dearCustomer: 'errorPages.selfExcluded.dearCustomer', cruksContent: 'errorPages.selfExcluded.cruksContent', content: 'errorPages.selfExcluded.content', description: 'errorPages.selfExcluded.description', logout: true }
    },
    [apiErrorType.VERIFICATION]: {
      default: { title: 'errorPages.verificationFailed.title', content: 'errorPages.rejectFromAISG.content', type: 'onboarding', btnName: 'close', btnAction: 'close' },
    }
  },
  [apiErrorCode.GAMSTOP_SELF_EXCLUDED]: {
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.selfExcluded.title', dearCustomer: 'errorPages.selfExcluded.dearCustomer', cruksContent: 'errorPages.selfExcluded.cruksContent', content: 'errorPages.selfExcluded.content', description: 'errorPages.selfExcluded.description', logout: true }
    },
  },
  [apiErrorCode.OASIS_BAN_EXIST]: {
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.oasisBanExist.title', dearCustomer: 'errorPages.oasisBanExist.dearCustomer', description: 'errorPages.oasisBanExist.description', topIcon: 'lock', className: 'oasis-ban'},
    },
    [apiErrorType.VERIFICATION]: {
      default: { title: 'errorPages.verificationFailed.title', content: 'errorPages.jumio.oasis.yourAccountWasSuspended', type: 'onboarding', btnName: 'close', btnAction: 'close' },
    },
  },
  // [apiErrorCode.PANIC_FROM_USER]: null,
  // [apiErrorCode.REJECT_FROM_BGC]: null,
  // [apiErrorCode.SECURITY_BLOCKED]: null,
  [apiErrorCode.ETC]: { // ACCOUNT_LOGIN_LIMIT_ACCESS, GAMSTOP_SELF_EXCLUDED, CRUKS_SELF_EXCLUDED, AISG_SELF_EXCLUDED, ACCOUNT_LOCKED, OASIS_BAN_EXIST 이 아닐 경우
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.index.title', dearCustomer: 'errorPages.index.dearCustomer', description: 'errorPages.index.description', logout: true }
    }
  },
});

const alreadyVerifiedMobile = () => ({
  [apiErrorCode.ALREADY_VERIFIED_MOBILE]: null,
});

const emailExists = () => ({
  [apiErrorCode.EMAIL_EXISTS]: null
});

const failedSendEmailVerification = () => ({
  [apiErrorCode.FAILED_SEND_EMAIL_VERIFICATION]: null,
  [apiErrorCode.FAILED_SEND_EMAIL_VERIFICATIONCODE]: null,
});

const invalidRequest = () => ({
  [apiErrorCode.AVAILABLE_EMAIL_ONLY]: null,
  [apiErrorCode.ALREADY_HAVE_RG_PENDING_LIMIT]: null,
  [apiErrorCode.DUPLICATE_IBAN]: null,
  [apiErrorCode.EMAIL_RESEND_TERM_LIMITED]: null,
  [apiErrorCode.EMAIL_RESEND_LIMITED]: null,
  [apiErrorCode.EXPIRED_VERIFICATIONCODE]: null,
  [apiErrorCode.FAILED_SELF_EXCLUSION_CHECK]: {
    [apiErrorType.VERIFICATION]: {
      default: { title: 'errorPages.verificationFailed.title', content: 'errorPages.accountSuspended.verificationSuspended', type: 'onboarding', btnName: 'close', btnAction: 'close' },
    }
  },
  [apiErrorCode.INVALID_REQUEST]: null,
  [apiErrorCode.INVALID_MAIDEN_NAME]: null,
  [apiErrorCode.INVALID_STREET_NAME]: null,
  [apiErrorCode.INVALID_HOUSE_NUMBER]: null,
  [apiErrorCode.INVALID_POSTAL_CODE]: null,
  [apiErrorCode.INVALID_FIRST_NAME]: null,
  [apiErrorCode.INVALID_LAST_NAME]: null,
  [apiErrorCode.INVALID_EMAIL]: null,
  [apiErrorCode.INVALID_DOB]: null,
  [apiErrorCode.INVALID_VERIFICATION_STATUS]: null,
  [apiErrorCode.INVALID_EMAIL_VERIFICATION_CODE]: null,
  [apiErrorCode.INVALID_IBANCODE]: null,
  [apiErrorCode.INVALID_NATIONALITY]: null,
  [apiErrorCode.INVALID_EXPECTED_MONTHLY_DEPOSIT]: null,
  [apiErrorCode.INVALID_ANNUAL_EARNING]: null,
  [apiErrorCode.INVALID_INDUSTRY_TYPE]: null,
  [apiErrorCode.INVALID_AMOUNT]: null,
  [apiErrorCode.INVALID_VERIFICATIONCODE]: null,
  [apiErrorCode.NEGATIVE_TIME_DURATION_NOT_ALLOWED]: null,
  [apiErrorCode.NEGATIVE_TIME_INTERVAL_NOT_ALLOWED]: null,
  [apiErrorCode.NEGATIVE_AMOUNT_NOT_ALLOWED]: null,
  [apiErrorCode.P2P_INVALID_TRANSFER_TYPE]: null,
  [apiErrorCode.RESTRICTED_EMAIL_ADDRESS]: null,
  [apiErrorCode.REJECT_FROM_BGC]: {
    [apiErrorType.VERIFICATION]: {
      default: { title: 'errorPages.verificationFailed.title', type: 'onboarding', content: 'errorPages.rejectFromEPIS.content' }
    }
  },
  [apiErrorCode.UNMATCHED_VERIFICATIONCODE]: null,
  [apiErrorCode.USER_INFO_ALREADY_EXIST]: {
    [apiErrorType.VERIFICATION]: {
      default: { title: 'errorPages.verificationFailed.title', content: 'errorPages.alreadyExist.content', type: 'onboarding', logout: true },
    }
  }
});

const invalidPlayer = () => ({
  [apiErrorCode.INVALID_PLAYER]: null,
  [apiErrorCode.P2P_SOURCE_LOCKBALANCE]: null,
  [apiErrorCode.P2P_SOURCE_RESTRICTED_REGION]: null,
  [apiErrorCode.P2P_DUPLICATE_REQUEST]: null,
  [apiErrorCode.P2P_TARGET_NOT_FOUND]: null,
  [apiErrorCode.ETC]: null,
});

const invalidPostalCode = () => ({
  [apiErrorCode.INVALID_POSTALCODE]: null,
  [apiErrorCode.INVALID_POSTAL_CODE]: null,
});

const invalidTransactionAmount = () => ({
  [apiErrorCode.INVALID_TRANSACTION_AMOUNT]: null,
  [apiErrorCode.INVALID_TRANSACTION_AMOUNT_NOT_ZERO]: null,
});

const invalidAgent = () => ({
  [apiErrorCode.INVALID_AGENT]: null,
  [apiErrorCode.AGENT_NOT_FOUND]: null,
});

const notEnoughBalance = () => ({
  [apiErrorCode.NOT_ENOUGH_BALANCE]: null,
  [apiErrorCode.P2P_TARGET_TRANSFER_EXCEEDED]: null,
  [apiErrorCode.P2P_SOURCE_TRANSFER_EXCEEDED]: null,
});

const responsibleGaming = () => ({
  [apiErrorCode.FAILED_SELF_EXCLUSION_CHECK]: {
    [apiErrorType.VERIFICATION]: {
      default: { title: 'errorPages.verificationFailed.title', content: 'errorPages.accountSuspended.verificationSuspended', type: 'onboarding', btnName: 'close', btnAction: 'close' },
    }
  },
  [apiErrorCode.LOGIN_TIME_LIMIT_REACHED]: {
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.loginlimit.title', dearCustomer: 'errorPages.loginlimit.dearCustomer', content: 'errorPages.loginlimit.loginTimeLimit', description: 'errorPages.loginlimit.description' }
    }
  },
  [apiErrorCode.RE_LOGIN_TIME_LIMIT_REACHED]: {
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.loginlimit.title', dearCustomer: 'errorPages.loginlimit.dearCustomer', content: 'errorPages.loginlimit.reLoginTimeLimit', description: 'errorPages.loginlimit.description' }
    }
  },
  [apiErrorCode.LOGIN_COUNT_LIMIT_REACHED]: {
    [apiErrorType.AUTH]: {
      default: { title: 'errorPages.loginlimit.title', dearCustomer: 'errorPages.loginlimit.dearCustomer', content: 'errorPages.loginlimit.monthlyLoginFrequency', description: 'errorPages.loginlimit.description' }
    }
  },
  [apiErrorCode.AISG_FAILED_SELF_EXCLUSION_CHECK]: {
    [apiErrorType.VERIFICATION]: {
      default: { title: 'errorPages.verificationFailed.title', content: 'errorPages.rejectFromAISG.content', type: 'onboarding' },
    }
  },
  [apiErrorCode.GAMSTOP_SELF_EXCLUDED]: {
    [apiErrorType.VERIFICATION]: {
      default: { title: 'errorPages.verificationFailed.title', content: 'errorPages.accountSuspended.verificationSuspended', type: 'onboarding', btnName: 'close', btnAction: 'close', logout: true },
    }
  },
});

const aisgRegistrationInvalidInformation = () => ({
  [apiErrorType.VERIFICATION]: {
    default: { title: 'errorPages.verificationFailed.title', content: 'errorPages.rejectFromEPIS.content', type: 'onboarding' },
  }
});

const aisgRegistrationInternalError = () => ({
  [apiErrorType.VERIFICATION]: {
    default: { title: 'errorPages.verificationFailed.title', content: 'errorPages.rejectFromEPIS.content', type: 'onboarding' },
  }
});

const aisgRegistrationInvalidOperation = () => ({
  [apiErrorType.VERIFICATION]: {
    default: { title: 'errorPages.verificationFailed.title', content: 'errorPages.rejectFromEPIS.content', type: 'onboarding' },
  }
});

const aisgRegistrationNotVerified = () => ({
  [apiErrorType.VERIFICATION]: {
    default: { title: 'errorPages.verificationFailed.title', content: 'errorPages.rejectFromEPIS.content', type: 'onboarding' },
  }
});

const aisgRegistrationInvalidHid = () => ({
  [apiErrorType.VERIFICATION]: {
    default: { title: 'errorPages.verificationFailed.title', content: 'errorPages.rejectFromEPIS.content', type: 'onboarding' },
  }
});

const excludedByService = () => ({
  [apiErrorType.VERIFICATION]: {
    default: { title: 'errorPages.verificationFailed.title', content: 'errorPages.rejectFromEPIS.content', type: 'onboarding' },
  }
});

const internalError = () => ({
  [apiErrorCode.INTERNAL_ERROR]: {
    [apiErrorType.VERIFICATION]: {
      default: { title: 'errorPages.verificationFailed.title', content: 'errorPages.unknownError.content', type: 'onboarding', btnName: 'close', btnAction: 'close' }
    }
  },
  [apiErrorCode.INVALID_PLAYER]: null,
  [apiErrorCode.ADD_KYC_DOCUMENT_FAILED]: null,
  [apiErrorCode.AISG_UPDATE_INFORMATION_FAILED]: null,
  [apiErrorCode.PARENT_AGENT_NOT_FOUND]: null,
  [apiErrorCode.INVALID_WALLET_SERVICE_RESPONSE]: null,
  [apiErrorCode.INVALID_ACCESS_TYPE]: null,
  [apiErrorCode.FAILED_WALLET_MIGRATION]: null,
  [apiErrorCode.INVALID_SINGLEWALLET_STATUS_CODE]: null,
  [apiErrorCode.REQUEST_FAILED]: null,
  [apiErrorCode.PLAYER_NICKNAME_NOT_SPECIFIED]: null,
  [apiErrorCode.MOVE_TICKET_PROCESSING]: null,
  [apiErrorCode.SYSTEM_CONFIGURATION_IS_NULL]: null,
  [apiErrorCode.OASIS_CREATE_BAN_FAILED]: null,
  [apiErrorCode.EDIT_ACCOUNT_DETAIL_FAILED]: null,
  [apiErrorCode.EDIT_ACCOUNT_KYC_STATUS_FAILED]: null,
  [apiErrorCode.PLAYER_NOT_FOUND]: null,
});

const userInfoNotVerified = () => ({
  [apiErrorCode.USER_INFO_NOT_VERIFIED]: {
    [apiErrorType.VERIFICATION]: {
      default: { title: 'errorPages.notVerified.title', content: 'errorPages.notVerified.content', type: 'onboarding', btnName: 'upload', btnAction: 'upload' },
      [siteIds.GGPDE]: { title: 'errorPages.notVerified.title', content: 'errorPages.notVerified.content', type: 'onboarding', btnName: 'close', btnAction: 'close' }
    }
  },
  [apiErrorCode.REJECT_FROM_BGC]: {
    [apiErrorType.VERIFICATION]: {
      default: { title: 'errorPages.verificationFailed.title', content: 'errorPages.rejectFromEPIS.content', type: 'onboarding' }
    }
  },
  [apiErrorCode.KYC_UPLOAD_REQUIRED]: {
    [apiErrorType.VERIFICATION]: {
      default: { title: 'errorPages.notVerified.title', content: 'errorPages.kycUploadRequired.content', type: 'onboarding', btnName: 'upload', btnAction: 'upload' },
      [siteIds.GGPDE]: { title: 'errorPages.sofort.pepMatch.title', content: 'errorPages.sofort.pepMatch.content.de', type: 'onboarding', btnName: 'close', btnAction: 'close' },
      [siteIds.GGPNL]: { title: 'errorPages.sofort.pepMatch.title', content: 'errorPages.sofort.pepMatch.content.nl', type: 'onboarding', btnName: 'close', btnAction: 'close' },
    }
  },
  [apiErrorCode.USER_INFO_NOT_VERIFIED_AND_RETRY]: null,
  [apiErrorCode.USER_INFO_NOT_VERIFIED_AND_PASS]: {
    [apiErrorType.VERIFICATION]: {
      default: { title: 'errorPages.selfExcluded.title', content: 'errorPages.accountSuspended.content', type: 'onboarding', btnName: 'close', btnAction: 'close' },
    }
  }
});

const userInfoAlreadyExist = () => ({
  [apiErrorCode.USER_INFO_ALREADY_EXIST]: {
    [apiErrorType.VERIFICATION]: {
      default: { title: 'errorPages.verificationFailed.title', content: 'errorPages.alreadyExist.content', type: 'onboarding', logout: true },
    }
  }
});

const userInfoCheck = () => ({
  [apiErrorCode.PEP_WATCH_MATCHED]: {
    [apiErrorType.VERIFICATION]: {
      default: { title: 'onBoarding.title.accountVerification', content: 'onBoarding.screening.mergeDescription', type: 'onboarding', btnName: 'close', btnAction: 'close', logout: true },
    }
  }
});
