<template>
  <GpFormTemplate rg-deposit-limit>
    <template v-if="title && description" #header>
      <GpDescription v-if="description" :message="description" />
      <GpUlDescriptionList v-if="subDescription" :message="subDescription" />
      <GpDescription v-if="structure.config.verifyFreeze" :message="verifyFreezeDescription" />
    </template>
    <template #content>
      <InputDepositLimitTable :rolling="rolling" :currency-symbol="currencySymbol" :model="model" :current="current" />
      <ValidationErrors v-if="limitErrorMsg" class="support-error-msg" :error-msg="limitErrorMsg" />
    </template>
    <template #footer="{validate, invalid}">
      <GpButton type="submit-green" @click="openModal" :disabled="invalid || disabled">{{ $t('submit') }}</GpButton>
    </template>
  </GpFormTemplate>
</template>

<script>
import _ from 'lodash';
import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import { getCurrencySymbol } from '@shared/utils/currencyUtils.mjs';
import InputDepositLimitTable from '@/views/components/pages/responsible-gaming/template/inputs/InputDepositLimitTable.vue';
import { useExceedMaximumDepositLimit } from '@/constants/base/responsible-gaming';
import { siteIds } from '@/constants/base/siteMap';
import ResponsibleProgressModal from '@/views/components/pages/responsible-gaming/modal/ResponsibleProgressModal.vue';
import GpFormTemplate from '@/views/components/gg-pass/GpFormTemplate.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import GpUlDescriptionList from '@/views/components/gg-pass/GpUlDescriptionList.vue';

export default {
  name: 'RgDepositLimit',
  lexicon: 'responsibleGaming',
  components: { GpUlDescriptionList, InputDepositLimitTable, GpFormTemplate, GpDescription, GpButton },
  props: {
    structure: { type: Specific, default: null },
    title: { type: String, default: null },
    description: { type: String, default: null, }
  },
  data() {
    return {
      errorMsg: null,
      rolling: null,
      currencySymbol: null,
      info: null,
      current: {
        Currency: null,
        CurrentDailyDepositLimit: null,
        CurrentWeeklyDepositLimit: null,
        CurrentMonthlyDepositLimit: null,
        CurrentExternalMonthlyDepositLimit: null,

        DailyDepositLimitApplyAt: null,
        WeeklyDepositLimitApplyAt: null,
        MonthlyDepositLimitApplyAt: null,
        ExternalMonthlyDepositLimitApplyAt: null,

        Day1Limit: null,
        Day7Limit: null,
        Day30Limit: null,
      },
      model: {
        RequestDailyDepositLimit: null,
        RequestWeeklyDepositLimit: null,
        RequestMonthlyDepositLimit: null,

        DailyNoLimit: false,
        WeeklyNoLimit: false,
        MonthlyNoLimit: false,
      },
      limitErrorMsg: null,
    };
  },
  computed: {
    site: state('env', 'site'),
    csEmail: state('env', 'csEmail'),
    token: state('user', 'token'),
    myInfo: state('user', 'userInfo'),
    calculateList() {
      return [
        { type: 'monthly', dmi: this.myInfo.PersonalInfo.DMI, regulation: this.current.RegulationMonthlyDepositLimit, current: this.current.CurrentMonthlyDepositLimit, request: this.model.RequestMonthlyDepositLimit, noLimit: this.model.MonthlyNoLimit, valueType: 'amount', currencySymbol: this.currencySymbol, rolling: this.rolling, },
        { type: 'weekly', dmi: this.myInfo.PersonalInfo.DMI, regulation: this.current.RegulationWeeklyDepositLimit, current: this.current.CurrentWeeklyDepositLimit, request: this.model.RequestWeeklyDepositLimit, noLimit: this.model.WeeklyNoLimit, valueType: 'amount', currencySymbol: this.currencySymbol, rolling: this.rolling, },
        { type: 'daily', dmi: this.myInfo.PersonalInfo.DMI, regulation: this.current.RegulationDailyDepositLimit, current: this.current.CurrentDailyDepositLimit, request: this.model.RequestDailyDepositLimit, noLimit: this.model.DailyNoLimit, valueType: 'amount', currencySymbol: this.currencySymbol, rolling: this.rolling, },
      ];
    },
    exceedList() {
      return [
        { type: 'monthly', current: this.current.CurrentMonthlyDepositLimit, limit: this.current.Day30Limit, request: this.model.RequestMonthlyDepositLimit, valueType: 'amount', currencySymbol: this.currencySymbol, rolling: this.rolling, },
        { type: 'weekly', current: this.current.CurrentWeeklyDepositLimit, limit: this.current.Day7Limit, request: this.model.RequestWeeklyDepositLimit, valueType: 'amount', currencySymbol: this.currencySymbol, rolling: this.rolling, },
        { type: 'daily', current: this.current.CurrentDailyDepositLimit, limit: this.current.Day1Limit, request: this.model.RequestDailyDepositLimit, valueType: 'amount', currencySymbol: this.currencySymbol, rolling: this.rolling, },
      ];
    },
    subDescription() {
      if (this.isGGPBE) {
        if (this.isRolling) return this.$t('_.subDescription.weeklyDepositLimit', { utcInfo: this.structure.config.timeZoneType });
        return this.$t('_.subDescription.weeklyCalendar', { utcInfo: this.structure.config.timeZoneType });
      }
      if (this.isRolling) return this.$t('_.subDescription.depositLimit', { utcInfo: this.structure.config.timeZoneType });
      return this.$t('_.subDescription.calendar', { utcInfo: this.structure.config.timeZoneType });
    },
    verifyFreezeDescription() {
      return this.$t('_.verifyFreeze.depositVerifyFreeze');
    },
    isExceedMaximum() {
      return useExceedMaximumDepositLimit.includes(this.site);
    },
    maxLimit() {
      return 1000000000;
    },
    isRolling() {
      return this.rolling === 'Rolling';
    },
    /**
     * B : Current(Existing) Limit < Request(New) Limit < Regulation Limit(RG Limit Guide) < DMI Limit
     * C : Current(Existing) Limit < Regulation Limit(RG Limit Guide) < Request(New) Limit < DMI Limit
     * D : Current(Existing) Limit < Regulation Limit(RG Limit Guide) < DMI Limit < Request(New) Limit
     * E : Regulation Limit(RG Limit Guide) < Current(Existing) Limit < Request(New) Limit < DMI Limit
     * F : Regulation Limit(RG Limit Guide) < Current(Existing) Limit < DMI Limit < Request(New) Limit
     * @returns {boolean}
     */
    checkMonthlyLimitHangtimeForGGPNL() {
      return this.current?.CurrentMonthlyDepositLimit < this.model?.RequestMonthlyDepositLimit;
    },
    /**
     * C : Current(Existing) Limit <= Regulation Limit(RG Limit Guide) < Request(New) Limit < DMI Limit
     * D : Current(Existing) Limit <= Regulation Limit(RG Limit Guide) < DMI Limit < Request(New) Limit
     * @returns {boolean}
     */
    checkMonthlyLimitSurveyForGGPNL() {
      return this.current?.CurrentMonthlyDepositLimit <= this.current?.RegulationMonthlyDepositLimit && this.current?.RegulationMonthlyDepositLimit < this.model.RequestMonthlyDepositLimit;
    },
    /**
     * D : Current(Existing) Limit < Regulation Limit(RG Limit Guide) < DMI Limit < Request(New) Limit
     * F : Regulation Limit(RG Limit Guide) < Current(Existing) Limit < DMI Limit < Request(New) Limit
     * @returns {boolean}
     */
    checkMonthlyLimitUploadForGGPNL() {
      return this.myInfo.PersonalInfo.DMI <= this.model?.RequestMonthlyDepositLimit;
    },
    isGGPBE() {
      return this.site === siteIds.GGPBE;
    },
    isGGPNL() {
      return this.site === siteIds.GGPNL;
    },
    isCashierLimitSite() {
      return [siteIds.GGPUK].includes(this.site);
    },
    disabled() {

      /**
       *
       * 1. 같은 값이 하나라도 있을 경우 버튼 Disabled 처리 ( 예시, Daily 기존값이 50 이고 입력값이 50일 시 비활성화 )
       *   1-1. No Limit 에도 적용 ( No Limit 체크 박스가 선택 된 상태에서 기존 값이 No Limit 인 경우에도 비활성화 )
       * 2. 모든 입력값이 없을때 비활성화 처리
       *   2-1. No Limit이 체크되있는 경우, 기존 값이 No Limit이 아닌 경우 모든 입력값이 없어도 활성화 ( 기존 값과 입력값이 다르다로 판정 )
       *
       * **/

      if (this.limitErrorMsg) return true;
      const { CurrentMonthlyDepositLimit, CurrentWeeklyDepositLimit, CurrentDailyDepositLimit } = this.current;
      const { RequestMonthlyDepositLimit, RequestWeeklyDepositLimit, RequestDailyDepositLimit, MonthlyNoLimit, WeeklyNoLimit, DailyNoLimit } = this.model;

      let requestMonthly = (RequestMonthlyDepositLimit || parseInt(RequestMonthlyDepositLimit) === 0) ? parseInt(RequestMonthlyDepositLimit) : null;
      let requestWeekly = (RequestWeeklyDepositLimit || parseInt(RequestWeeklyDepositLimit) === 0) ? parseInt(RequestWeeklyDepositLimit) : null;
      let requestDaily = (RequestDailyDepositLimit || parseInt(RequestDailyDepositLimit) === 0) ? parseInt(RequestDailyDepositLimit) : null;

      requestMonthly = MonthlyNoLimit ? null : requestMonthly;
      requestWeekly = WeeklyNoLimit ? null : requestWeekly;
      requestDaily = DailyNoLimit ? null : requestDaily;

      const sameValue = CurrentDailyDepositLimit === requestDaily && CurrentWeeklyDepositLimit === requestWeekly && CurrentMonthlyDepositLimit === requestMonthly;
      const nullCheck = (!requestDaily && requestDaily !== 0) && (!requestWeekly && requestWeekly !== 0) && (!requestMonthly && requestMonthly !== 0);

      if(this.isGGPNL) {
        if(!CurrentMonthlyDepositLimit && !RequestMonthlyDepositLimit) return true;
        return nullCheck;
      }

      if(nullCheck) {
        let isDailyNoLimit = false;
        let isWeeklyNoLimit = false;
        let isMonthlyNoLimit = false;
        if(MonthlyNoLimit) isMonthlyNoLimit = (requestMonthly !== CurrentMonthlyDepositLimit);
        if(WeeklyNoLimit) isWeeklyNoLimit = (requestWeekly !== CurrentWeeklyDepositLimit);
        if(DailyNoLimit) isDailyNoLimit = (requestDaily !== CurrentDailyDepositLimit);

        if(isDailyNoLimit || isWeeklyNoLimit || isMonthlyNoLimit) return false;
      }

      return sameValue || nullCheck;
    },
    checkMaximumLimit() {
      const { Day30Limit, Day7Limit, Day1Limit } = this.current;
      const { RequestMonthlyDepositLimit, RequestWeeklyDepositLimit, RequestDailyDepositLimit } = this.model;

      return (Day1Limit < RequestDailyDepositLimit || Day7Limit < RequestWeeklyDepositLimit || Day30Limit < RequestMonthlyDepositLimit);
    },
  },
  watch: {
    'model.RequestMonthlyDepositLimit': 'update',
    'model.RequestWeeklyDepositLimit': 'update',
    'model.RequestDailyDepositLimit': 'update',

    'model.MonthlyNoLimit': 'update',
    'model.WeeklyNoLimit': 'update',
    'model.DailyNoLimit': 'update',
  },
  methods: {
    async initialize() {
      const { limit, deposit } = await this.structure.current;

      this.info = this.rgInfo;
      this.current = {
        ...this.current,
        ...limit,
      };
      this.currencySymbol = getCurrencySymbol(this.current.Currency);
      this.rolling = this.structure.config.DepositLimitPeriodType;

      this.current.Day30Limit = _.isNumber(deposit.Day30Limit) ? deposit.Day30Limit : this.maxLimit;
      this.current.Day7Limit = _.isNumber(deposit.Day7Limit) ? deposit.Day7Limit : this.maxLimit;
      this.current.Day1Limit = _.isNumber(deposit.Day1Limit) ? deposit.Day1Limit : this.maxLimit;

      let cashier30Limit = _.isNumber(deposit.Day30Limit) ? deposit.Day30Limit : null;
      let cashier7Limit = _.isNumber(deposit.Day7Limit) ? deposit.Day7Limit : null;
      let cashier1Limit = _.isNumber(deposit.Day1Limit) ? deposit.Day1Limit : null;

      if (!this.isCashierLimitSite) {
        cashier30Limit = null;
        cashier7Limit = null;
        cashier1Limit = null;
      }

      this.current.CurrentMonthlyDepositLimit = _.isNumber(limit.CurrentMonthlyDepositLimit) ? limit.CurrentMonthlyDepositLimit : cashier30Limit;
      this.current.CurrentWeeklyDepositLimit = _.isNumber(limit.CurrentWeeklyDepositLimit) ? limit.CurrentWeeklyDepositLimit : cashier7Limit;
      this.current.CurrentDailyDepositLimit = _.isNumber(limit.CurrentDailyDepositLimit) ? limit.CurrentDailyDepositLimit : cashier1Limit;
    },
    update() {
      if (this.limitErrorMsg) this.limitErrorMsg = null;
    },
    async request({ payload, files, last }) {
      const depositModel = this.createPayload();
      return this.$rg.request({...payload, ...depositModel});
    },
    createModalOptions () {
      const isVerifyFreeze = this.isGGPNL ? false : this.structure.config.verifyFreeze;
      const desc = isVerifyFreeze ? 'responsibleGaming.modal.description.verifyFreeze' : 'responsibleGaming.modal.description.selfLimit';
      const descModel = {
        hangTime: 'responsibleGaming.modal.description.hangTimeLimit',
        exceed: 'responsibleGaming.modal.description.exceedDescription',
        desc,
      };

      /*
      // A
      // this.model.RequestMonthlyDepositLimit = 500;
      // this.current.CurrentMonthlyDepositLimit = 1000;
      // this.current.RegulationMonthlyDepositLimit = 700;
      // this.myInfo.PersonalInfo.DMI = 234234;
      // G
      // this.model.RequestMonthlyDepositLimit = 500;
      // this.current.RegulationMonthlyDepositLimit = 1000;
      // this.current.CurrentMonthlyDepositLimit = 600;
      // this.myInfo.PersonalInfo.DMI = 600;

      // B
      // this.model.RequestMonthlyDepositLimit = 600;
      // this.current.CurrentMonthlyDepositLimit = 500;
      // this.current.RegulationMonthlyDepositLimit = 700;
      // this.myInfo.PersonalInfo.DMI = 800;
      // E 확인 필요
      // this.model.RequestMonthlyDepositLimit = 700;
      // this.current.CurrentMonthlyDepositLimit = 600;
      // this.current.RegulationMonthlyDepositLimit = 500;
      // this.myInfo.PersonalInfo.DMI = 800;

      // C
      // this.model.RequestMonthlyDepositLimit = 600;
      // this.current.CurrentMonthlyDepositLimit = 500;
      // this.current.RegulationMonthlyDepositLimit = 600;
      // this.myInfo.PersonalInfo.DMI = 700;

      // D
      // this.model.RequestMonthlyDepositLimit = 700;
      // this.current.CurrentMonthlyDepositLimit = 500;
      // this.current.RegulationMonthlyDepositLimit = 600;
      // this.myInfo.PersonalInfo.DMI = 700;

      // F 확인 필요
      // this.model.RequestMonthlyDepositLimit = 800;
      // this.current.CurrentMonthlyDepositLimit = 600;
      // this.current.RegulationMonthlyDepositLimit = 500;
      // this.myInfo.PersonalInfo.DMI = 700;
      */
      if (this.isGGPNL) {
        if (this.checkMonthlyLimitHangtimeForGGPNL) { // B, C, D, E, F
          const survey = this.checkMonthlyLimitSurveyForGGPNL; // C, D
          const upload = this.checkMonthlyLimitUploadForGGPNL; // D, F
          const title = { default: this.$t('_.title.affordabilityQuestionnaire'), 2: this.$t('_.title.requestDmiIncrease') };
          const modalType = 'Questionnaire';
          const payload = this.createPayload();

          if (survey && upload) { // D
            return { title, first: 1, last: 4, calculateList: this.calculateList, modalType, payload, request: payload => this.$rg.request(payload), config: this.structure.config };
          }
          if (survey) { // C
            return { title, first: 1, last: 3, calculateList: this.calculateList, modalType, payload, request: payload => this.$rg.request(payload), config: this.structure.config };
          }
          if (upload) { // F
            return {
              title: { default: this.$t('_.modal.title'), 2: this.$t('_.title.requestDmiIncrease') },
              subTitle: { default: this.$t(`_.modal.subTitle.${this.structure.config.title}`) },
              modalType: 'Questionnaire',
              request: payload => this.$rg.request(payload),
              payload,
              first: 1,
              last: 2,
              calculateList: this.calculateList,
              isVerifyFreeze,
              descModel,
              config: this.structure.config
            };
          }
        }
      } else {
        if (this.isExceedMaximum && this.checkMaximumLimit) {
          return {
            title: { default: this.$t('_.modal.exceedTitle') },
            isExceed: true,
            modalType: 'HangTime',
            calculateList: this.exceedList,
            descModel,
            config: this.structure.config
          };
        }
        if (this.checkMaximumLimit) {
          this.limitErrorMsg = this.$t('responsibleGaming.error.increaseLimit', { csEmail: this.csEmail, support: this.$t('responsibleGaming.error.customerSupport') });
          return null;
        }
      }

      console.log('payload', this.createPayload(), this.current)

      return {
        title: { default: this.$t('_.modal.title') },
        subTitle: { default: this.$t(`_.modal.subTitle.${this.structure.config.title}`) },
        modalType: 'HangTime',
        request: () => this.$rg.request(this.createPayload()),
        calculateList: this.calculateList,
        isVerifyFreeze,
        descModel,
        config: this.structure.config
      };
    },
    createPayload() {
      const payload = { ...this.model };
      if (!this.model.DailyNoLimit && (this.current.CurrentDailyDepositLimit || this.current.CurrentDailyDepositLimit === 0) && !this.model.RequestDailyDepositLimit) {
        payload.RequestDailyDepositLimit = this.current.CurrentDailyDepositLimit;
      }
      if (!this.model.WeeklyNoLimit && (this.current.CurrentWeeklyDepositLimit || this.current.CurrentWeeklyDepositLimit === 0) && !this.model.RequestWeeklyDepositLimit) {
        payload.RequestWeeklyDepositLimit = this.current.CurrentWeeklyDepositLimit;
      }
      if (!this.model.MonthlyNoLimit &&  (this.current.CurrentMonthlyDepositLimit || this.current.CurrentMonthlyDepositLimit === 0)  && !this.model.RequestMonthlyDepositLimit) {
        payload.RequestMonthlyDepositLimit = this.current.CurrentMonthlyDepositLimit;
      }

      return payload;
    },
    async openModal() {
      const options = this.createModalOptions();
      if (!options) return;

      const r = await this.$modal(ResponsibleProgressModal, options);
    },
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[rg-deposit-limit] {
  .sub-description {.c(#737373); .fs(14);
    > ul {list-style: square;}
    > ul > li {list-style: square}
  }
  .support-error-msg {.mt(20);
    > li { .fs(16);
      > a {.c(@c-link); font-weight: 500;}
    }
  }
}

</style>