<template>
  <div auth-camera>
    <div class="contents-holder">
      <div class="header">
        <FontIcon name="video-settings" />
        <h2 v-html="$t('_.title')" />
      </div>
      <div class="contents">
        <div v-html="$t('_.messageCustomer')" />
        <div v-html="$t('_.messageDescription')" />
        <ul>
          <li>
            <label v-html="$t('_.messageNotice')" />
          </li>
        </ul>
      </div>
      <div class="button">
        <ProgressButton class="continue" button-name="continue" :progress="progress" @click="onContinue" />
        <ProgressButton class="close" button-name="close" :disabled="progress" @click="onClose" />
      </div>
    </div>
    <div class="modal" :class="{'fade-in': showModal }">
      <div class="dimmed" />
      <div class="contents-holder">
        <div class="header">
          <FontIcon name="exclamation-triangle" />
          <h2 v-html="$t('_.confirmTitle')" />
        </div>
        <div class="contents">
          <div v-html="$t('_.confirmDescription')" />
        </div>
        <div class="button">
          <ProgressButton class="back" button-name="back" @click="onBack" />
          <ProgressButton class="close" button-name="confirmClose" @click="onConfirmClose" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { close, requestClientPermission } from '@/utils/clientUtil';
import FontIcon from '@shared/components/common/FontIcon.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import { sleep } from '@shared/utils/commonUtils.mjs';

export default {
  name: 'AuthCamera',
  lexicon: 'onBoarding.authCamera',
  components: { FontIcon, ProgressButton },
  data() {
    return {
      showModal: false,
      progress: false,
    };
  },
  methods: {
    onBack() {
      this.showModal = false;
    },
    async onConfirmClose() {
      await close();
    },
    async onContinue() {
      this.progress = true;
      await requestClientPermission();
      await sleep(1000);
      this.progress = false;
    },
    onClose() {
      this.showModal = true;
    },
  },
  beforeDestroy() {
    this.progress = false;
  }
};
</script>

<style scoped lang="less">
@import '~@/less/proj.less';
[auth-camera] {
  >.contents-holder {
    .header {  .tc();
      [font-icon] { .fs(60); }
      h2 { .mt(12); }
    }
    .contents { .mt(28); .p(20); .bgc(#262626); .br(8); .tl(); .break-all(); white-space: normal; .medium();
      div + div { .mt(12); }
      ul { .mt(12); .pl(8);
        li { .flex(); }
        li:before { .cnt("•"); .mr(8); }
      }
    }
  }
  .dimmed { .wh(100%); .fix(); .t(0); .l(0); .bgc(rgba(0, 0, 0, 0.7)); .z(10); }
  .modal { .abs(); .wh(100%); .lt(0,0); .flex(); .items-center(); .justify-center(); .hidden(); .o(0);
    &.fade-in { .o(0.5s, 1); .visible(); }
    >.contents-holder { .z(20); .bgc(#171717); .mh(20) !important; .p(40, 20, 40, 20) !important; .br(8);
      .header { .tc();
        > [font-icon] { .fs(60); .c(#e03939); }
        > h2 { .mt(12); }
      }
      .contents { .mt(28); .break-all(); white-space: normal; .medium();
        div + div { .mt(12); }
      }
    }
  }
  .button {
    > [progress-button] { .fs(18);
      &.close { .bgc(#515151);
        @{hover-press} { .bgc(#515151); }
      }
      & + [progress-button] { .mt(12); }
    }
  }
}
</style>
