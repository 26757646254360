<template>
  <Modal alert-modal theme="my-white" :dim-close="false" @close="close">
    <template #title>
      <p v-if="showTitle" class="modal-title">{{ title }}</p>
      <a class="close" @click="close" v-if="showClose">
        <FontIcon name="close" />
      </a>
    </template>
    <slot name="content" class="body" />
    <slot name="footer" class="footer" />
    <template #floating>
      <slot name="floating" />
    </template>
  </Modal>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';
import Specific from '@shared/types/Specific';
import FontIcon from '@shared/components/common/FontIcon.vue';

export default {
  name: 'BaseModal',
  components: { FontIcon, Modal },
  props: {
    options: Specific,
    title: {
      type: String,
      default: 'Title'
    },
    dimClose: { type: Boolean, default: false },
    showTitle: { type: Boolean, default: true },
    showClose: { type: Boolean, default: true },
    allClose: {type: Boolean, default: true},
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    close() {
      if(this.allClose) this.$modalCloseAll();
      this.$emit('close');
    },
    resolve(){

      this.$emit('resolve');
    }
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
[modal] { .ph(0);
  @media (@ml-up) {
    .ph(20);
  }
}
[alert-modal] {
  > .dim {.bgc(rgba(0, 0, 0, 0.30))}
  > .panel {box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16) !important;}
  .close{.p(10)}
  .modal-title {.fs(16); font-weight: 600;}
  .body { flex: 1; }
  .footer { .pt(10); }
}
</style>
