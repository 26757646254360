<template>
  <div fi-verify class="field-wrapper">
    <div class="header">
      <template v-if="!isMobile && isTemporaryDisruption">
        <p class="desc temporary-disruption" v-html="$t('findId.description.temporaryDisruptionDescription')" />
        <br />
      </template>
      <p :class="['desc', {'no-margin' : !isMobile}]" v-html="$t('findId.description.verifyDesc', {length:4,brand : displayBrandName, method : isMobile ? mobileNumber : modelInfo.Email})" />
      <p v-if="!isMobile" class="desc extra-desc" v-html="$t('findId.description.verifyEmailDesc')" />
    </div>
    <ValidationProvider rules="required" class="content">
      <FpResendButton :isEmail="!isMobile" :model-info="modelInfo" :structure="structure" :resend-time="resendTime" @resetInterval="resetInterval" @resend="resend" />
      <VerifyPinCode v-model="modelInfo.VerificationCode" :structure="structure" :length="4" @input="updateCode" />
      <ValidationErrors class="error-msg" :errorMsg="structure.errorMsg" />
    </ValidationProvider>
  </div>
</template>

<script>
import { isExcludeGgpass } from '@/utils/baseUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import VerifyPinCode from '@/views/components/common/VerifyPinCode.vue';
import Specific from '@shared/types/Specific';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { brandIds, displayBrandNames, siteIds } from '@/constants/base/siteMap.mjs';
import FpResendButton from '@/views/components/pages/forgot-password/FpResendButton.vue';
import fpErrorHandler from '@/mixins/forgot-password/fpErrorHandler';
import OpenEmailAppButton from '@/views/components/gg-pass/OpenEmailAppButton.vue';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import VerifyRequestMessageModal from '@/views/components/common/modal/VerifyRequestMessageModal.vue';

export default {
  name: 'FiVerify',
  mixins: [fpErrorHandler],
  props: {
    structure: Specific,
    modelInfo: { type: Specific, default: null },
  },
  data() {
    return {
      intervalCount: 0,
      resendTime: 0,
      resendProgress: false,
      isTemporaryDisruption: false,
    };
  },
  components: { OpenEmailAppButton, FpResendButton, ProgressButton, VerifyPinCode, FontIcon },
  computed: {
    site: state('env', 'site'),
    brand: state('env', 'brand'),
    gpBrand: state('env', 'gpBrand'),
    brandNames(){
      return displayBrandNames
    },
    isMobile() {
      return ['VerificationMobile', 'IdMobile'].includes(this.modelInfo.RequestType);
    },
    method() {
      return this.$t(['VerificationMobile', 'IdMobile'].includes(this.modelInfo.RequestType) ? 'mobile' : 'email');
    },
    findKey: state('query', 'findKey'),
    desc() {
      return 'Enter the {mobile number / email address} that is registered on your {BrandName} account';
    },
    isMBP() { return [siteIds.GGPUKE, siteIds.EVPUKE].includes(this.site); },
    isGgpass() {
      return !isExcludeGgpass(this.site);
    },
    showEmailButton() {
      return this.isGgpass && this.emailMode;
    },
    displayBrandName() {
      const key = Object.keys(brandIds).find(key => brandIds[key] === this.brand);
      return this.brandNames[key];
    },
    mobileNumber(){
      const countryNumber = this.modelInfo.PhoneCountryCode ? `+${this.modelInfo.PhoneCountryCode}` : null;
      return `${countryNumber} ${this.modelInfo.MobileNumber}`;
    },
  },
  watch: {
    'modelInfo.VerificationCode': function () {this.$emit('clear-error'); },
  },
  methods: {
    async loadCaptcha() {
      this.captcha = {};
      const action = 'FindIdVerify';
      this.captcha = this.isMBP ? await this.$services.captcha.setGeeCaptcha() : await this.$services.captcha.setRecaptcha(action);
    },
    async resend() {
      if (this.resendProgress) return;
      this.resendProgress = true;
      await this.loadCaptcha();
      const api = this.modelInfo.RequestType.includes('VerificationEmail') ? 'requestEmailCert' : this.modelInfo.RequestType.includes('VerificationMobile') ? 'requestMobileCert' : 'requestGgpassEmailCert';
      const r = await this.$services.findId[api]({ ...this.captcha, ...this.modelInfo, IsResend: true });
      this.resendProgress = false;
      if(r?.error){
        switch (r.key) {
          case apiErrorCode.EMAIL_RESEND_TERM_LIMITED:
          case apiErrorCode.PHONE_RESEND_TERM_LIMITED:
          case 'limit':{
            this.resendTime = +r.value;
            await this.$modal(VerifyRequestMessageModal, { isSend: false, type: this.isMobile ? 'sms' : 'email' });
            return;
          }
          case apiErrorCode.RESEND_LIMITED:
          case apiErrorCode.EMAIL_RESEND_LIMITED:
          case apiErrorCode.PHONE_RESEND_LIMITED:{
            this.resendTime = +r.value;
            await this.$modal(VerifyRequestMessageModal, { isSend: false, type: this.isMobile ? 'sms' : 'email', error: r?.error });
            return;
          }
          case apiErrorCode.ACCOUNT_NOT_FOUND: {
            this.structure.error = true;
            const method = this.structure.methodType === 'Email' ? this.$t('emailAddress') : this.$t('mobileNumber');
            const brand = this.brand === brandIds.WSOP ? "GGPoker.CA" : this.brand
            this.structure.errorMsg = this.$t(r.key, { method: method, brand: brand });
            break;
          }
          default : {
            this.structure.error = true;
            this.structure.errorMsg = this.$t(r.key, { brand: this.brand, csEmail : this.csEmail });
            break;
          }
        }
      }

      this.resendTime = 59;
      await this.$modal(VerifyRequestMessageModal, { isSend: true, type: this.isMobile ? 'sms' : 'email' });
    },
    resetInterval() {
    },
    updateCode() {

    },
    initialize() {
      this.modelInfo.VerificationCode = null;
      this.resendTime = this.structure.resendInitTime;
      // TODO: [ean] 혹시 모르니 우선 주석으로 문구 미노출 대응
      // if ([brandIds.GGPCOM, brandIds.GGPOKERUK, brandIds.WSOP].includes(this.brand)) this.isTemporaryDisruption = true;
    },
  },
  mounted() {
    this.initialize();
  },
  beforeDestroy() {
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[fi-verify] { .flex(); .flex-dc(); flex: 1; justify-content: space-between;
  .desc {
    &.no-margin {margin-bottom: 0 !important;}
    &.extra-desc {margin-top: 0 !important;}
    > em {.c(white);}
  }
  .temporary-disruption { .c(#C64040) !important; margin-bottom: 0 !important; }
  .header {
    .sub-title {.fs(22);}
  }
  .content {
    .error-msg { .tc(); .mt(16) !important; }
  }
  .email-app {.flex(); .items-end();.justify-center();
    .btn-holder {.flex();.gap(10); .items-center();}
  }
}
</style>