<template>
  <div input-security-method>
    <RadioList type="vertical" :custom-radio="SecurityMethodRadioItem" :list="methodList" v-model="model.VerificationMethod" @open="openModal" />
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import { state } from '@shared/utils/storeUtils.mjs';
import RadioList from '@/views/components/gg-pass/RadioList.vue';
import { mfaLevel, mfaMethod } from '@/constants/base/my-page';
import SecurityMethodRadioItem from '@/views/components/pages/my-page/template/login-securty/SecurityMethodRadioItem.vue';
import { siteIds } from '@/constants/base/siteMap.mjs';
import MfaMethodAuthenticationModal from '@/views/components/pages/my-page/modal/login-security/MfaMethodAuthenticationModal.vue';
import MfaGoogleModal from '@/views/components/pages/my-page/modal/MfaGoogleModal.vue';

export default {
  name: 'InputSecurityMethod',
  lexicon: 'myInfo',
  components: { RadioList },
  props: {
    model: Specific,
    authInfo: Specific,
    userInfo: Specific,
    currentMfa: Specific,
  },
  data() {
    return {
      SecurityMethodRadioItem,
    };
  },
  computed: {
    site: state('env', 'site'),
    methodModel() {
      return {
        [mfaLevel.OFF]: [],
        [mfaLevel.LOW]: [mfaMethod.EMAIL, mfaMethod.MOBILE],
        // [mfaLevel.MEDIUM]: [mfaMethod.EMAIL, mfaMethod.MOBILE, mfaMethod.GOOGLE],
        [mfaLevel.HIGH]: [mfaMethod.EMAIL, mfaMethod.MOBILE, mfaMethod.GOOGLE],
      };
    },
    methodProperty() {
      return {
        [mfaMethod.EMAIL]: { iconType: 'font', icon: 'envelope-light', value: mfaMethod.EMAIL, label: this.$t('_.title.emailAuthentication'), content: this.authInfo.Email, isAuthenticated: this.authInfo.EmailAuthenticated, disableSite: null, },
        [mfaMethod.MOBILE]: { iconType: 'font', icon: 'mobile-regular', value: mfaMethod.MOBILE, label: this.$t('_.title.mobileAuthentication'), content: this.authInfo.MobileNumber, isAuthenticated: this.authInfo.MobileAuthenticated, disableSite: [siteIds.GGPOK, siteIds.OCNP] },
        [mfaMethod.GOOGLE]: { iconType: 'png', icon: 'icon_google.png', value: mfaMethod.GOOGLE, label: this.$t('_.googleAuthenticator'), content: this.authInfo.Google, isAuthenticated: this.authInfo.GoogleAuthenticated, disableSite: [siteIds.GGPUKE, siteIds.EVPUKE] }
      };
    },
    methodList() {
      const methodList = this.methodModel[this.model.SecurityLevel] || [];
      return methodList.filter(key => {
        const property = this.methodProperty[key];
        if (property.disableSite) {
          const disable = property.disableSite.includes(this.site);
          if(key === this.currentMfa?.VerificationMethod && this.model?.SecurityLevel === this.currentMfa?.SecurityLevel) return true;
          return !disable;
        }
        return true;
      }).map(v => this.methodProperty[v]);
    },
  },
  methods: {
    async openModal(type) {
      switch(type) {
        case mfaMethod.EMAIL: {
          this.model.SendVerificationType = 'EMAIL';
          await this.$modal(MfaMethodAuthenticationModal, { SendVerificationType: this.model.SendVerificationType });
          this.$emit('auth', this.model.SendVerificationType);
          return;
        }
        case mfaMethod.MOBILE: {
          this.model.SendVerificationType = 'MOBILE';
          await this.$modal(MfaMethodAuthenticationModal, { SendVerificationType: this.model.SendVerificationType });
          this.$emit('auth', this.model.SendVerificationType);
          return;
        }
        case mfaMethod.GOOGLE: {
          await this.$modal(MfaGoogleModal);
          this.$emit('auth', 'GOOGLE');
          return;
        }
      }
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[input-security-method] { .flex(); .flex-dc(); .gap(15); .w(100%);}
</style>