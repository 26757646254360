<template>
  <div forgot-password>
    <router-view v-if="config" :structure="structure" :model-info="modelInfo" :captcha="captcha" />
  </div>
</template>

<script>
import { setNewRelic, setOneTrust, setSegment } from '@/plugins/tracking';
import { ggpassNullQueries, isExcludeGgpass } from '@/utils/baseUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap.mjs';

export default {
  name: 'ForgotPassword',
  components: { },
  data() {
    return {
      queries: null,
      structure: {
        title: null,
        desc: null,
        back: false,
        success: false,
        error: false,
        errorMsg: null,
        errorDesc: null,
        errorCode: null,
        hasButton: true,
        completeReset: true,
        template: null,
        btnName: '',
        limitTime: 0,
        extraDesc: null,
        showButton: true,
        errorTitle: null,
        useCaptcha: false,
        isPasswordProcess: false,
        temporaryDisruptionDescription: '',
      },
      modelInfo: {
        CountryCode: null,
        MobileNumber: null,
        Email: null,
        Code: null,
        Token: null,
        VerificationCodeType: 'RESET_PASSWORD',
        Method: 'CODE',
        Password: null,
        ConfirmPassword: null,
        AccessToken: null,
        VerificationMethods: null,
        LoginMethod: null,
        NpApiType: null,
        UserName: null,
      },
      captcha: null,
      isLoad: false,
    };
  },
  computed: {
    title() {
      return /** @type { String }*/this.structure.title;
    },
    back() {
      return /** @type { Boolean }*/this.structure.back;
    },
    desc() {
      return /** @type { String }*/this.structure.desc;
    },
    site: state('env', 'site'),
    ggpass: state('env', 'ggpass'),
    npOnly: state('env', 'npOnly'),
    brand: state('env', 'brand'),
    theme: state('env', 'theme'),
    siteInfo: state('env', 'siteInfo'),
    userInfo: state('user', 'userInfo'),
    countryCode: state('user', 'countryCode'),
    closeUrl: state('query', 'closeUrl'),
    config: state('config', 'info'),
    selectedLang: state('query', 'selectedLang'),
    isMBP() {
      return [siteIds.GGPUKE, siteIds.EVPUKE].includes(this.site);
    },
  },
  methods: {
    async setUserInfo() {
      this.$store.commit('user/setUserInfo', await this.$services.base.getUserCountry());
    },
    async initialize() {
      if (!this.isMBP) await this.$services.captcha.reCaptchaInitialize();
      if(this.site === siteIds.GGPUKE) document.body.className = this.theme;

      // await this.localeInitialize();

      setNewRelic();
      setSegment();
      setOneTrust(this.queries);
    },
  },
  async mounted() {
    if (this.site === siteIds.GGVEGAS) {
      await this.replaceRouteName('NotProvide');
      return;
    }

    this.queries = ggpassNullQueries(this.$route.query);
    this.$store.commit('config/setInfo', this.npOnly || isExcludeGgpass(this.site) ? {} : await this.$services.ggpass.getConfig());

    await this.$fp.setScope(this);
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[forgot-password] { .pt(0); .h(calc(var(--innerHeight) - 80px)); .flex(); .flex-dc(); //.h(calc(var(--innerHeight) - 200px));
  .desc { .m(12, 0, 26); .fs(18, 22); .regular(); .c(#7F7F7F); }
  .field-wrapper { .h(100%); .rel();
    .bottom { .w(100%); .t(54%); .abs(); .tc(); .c(#ccc);
      a { .rel(); .underline();
        [font-icon] { .c(@c-red); .fs(14); .abs(); .rt(-16, 50%); .t-yc();}
      }
    }
  }
}
</style>