<template>
  <div fp-verify class="field-wrapper">
    <div>
      <VerifyResendButton :model-info="modelInfo" :structure="structure" api-type="RESET_PASSWORD" :action="action" @complete="resendComplete" />
      <VerifyPinCode v-model="modelInfo.Code" :structure="structure" type="nonSpecial" :length="6" @input="updateCode" />
      <ValidationErrors class="error-msg" :errorMsg="structure.errorMsg" />
    </div>

    <OpenEmailAppButton v-if="showEmailButton" :email="modelInfo.Email" />
  </div>
</template>

<script>
import { isExcludeGgpass } from '@/utils/baseUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import { brandIds, siteIds } from '@/constants/base/siteMap.mjs';
import VerifyPinCode from '@/views/components/common/VerifyPinCode.vue';
import Specific from '@shared/types/Specific';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import FpResendButton from '@/views/components/pages/forgot-password/FpResendButton.vue';
import fpErrorHandler from '@/mixins/forgot-password/fpErrorHandler';
import OpenEmailAppButton from '@/views/components/gg-pass/OpenEmailAppButton.vue';
import VerifyRequestMessageModal from '@/views/components/common/modal/VerifyRequestMessageModal.vue';
import VerifyResendButton from '@/views/components/common/VerifyResendButton.vue';

export default {
  name: 'FpVerify',
  mixins: [fpErrorHandler],
  props: {
    structure: Specific,
    modelInfo: { type: Specific, default: null },
  },
  data() {
    return {
      intervalCount: 0,
      captcha: null,
      interval: null,
      isProgress: false,
      pollingReset: false,
    };
  },
  components: { OpenEmailAppButton, FpResendButton, ProgressButton, VerifyPinCode, FontIcon, VerifyResendButton },
  computed: {
    site: state('env', 'site'),
    brand: state('env', 'brand'),
    isMBP() { return [siteIds.GGPUKE, siteIds.EVPUKE].includes(this.site); },
    isGgpass() {
      return !isExcludeGgpass(this.site);
    },
    showEmailButton() {
      return this.isGgpass && this.emailMode;
    },
    emailMode() {
      return this.modelInfo?.LoginMethod === 'Email';
    },
    mobileMode() {
      return this.modelInfo?.LoginMethod === 'Mobile';
    },
    action() {
      return this.isGgpass ? 'RESET_PASSWORD' : this.emailMode ? 'ForgotPasswordEmailSendCode' : 'ForgotPasswordMobileSendCode';
    }
  },
  watch: {
    'modelInfo.Code': function (v) {
      if (v.length >= 6) {
        this.$emit('clear-error');
      } else {
        this.structure.error = true;
      }
    },
  },
  methods: {
    async resendComplete(v) {
      const { isSend, error } = v;
      await this.$modal(VerifyRequestMessageModal, { isSend, type: this.modelInfo.SendVerificationType === 'EMAIL' ? 'email' : 'sms', error });
    },
    updateCode(v) {
      this.modelInfo.VerifyCode = v;
    },
    initialize() {
      this.structure.error = true;
      this.structure.title = 'fpTitle';
      this.structure.desc = 'forgotPassword.description.verifyCode';
      this.structure.extraDesc = this.modelInfo.SendVerificationType === 'MOBILE' ? null : this.$t('forgotPassword.description.emailDescription');
      this.structure.back = true;
      this.structure.errorCode = null;
      this.structure.showButton = true;
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[fp-verify] { .flex(); .flex-dc(); flex: 1; justify-content: space-between;
  .email-app {.flex(); .items-end();.justify-center();
    .btn-holder {.flex();.gap(10); .items-center();}
  }
  [verify-resend] {.flex(); .justify-end();}
}
</style>