<template>
  <div receiver-jumio>
  </div>
</template>

<script>
import { parseQuery } from '@shared/utils/urlUtils.mjs';
export default {
  name: 'ReceiverJumio',
  async mounted() {
    /**
     * poiId, poiResult : identity transaction id, 문서 검증 상태,
     * poaId, poaResult : document transaction id, 문서 검증 상태
     * */
    const { transactionReference: poiId, transactionStatus: poiResult, jumioIdScanReference: poaId, idScanStatus: poaResult } = parseQuery(location.search);
    console.log('%cjumio receiver result', 'color:yellow', poiId, poiResult, poaId, poaResult);

    const r = { key: 'RECEIVER_JUMIO' };
    if (!!poiId && !!poiResult) {
      r.transactionId = poiId;
      r.result = poiResult;
    } else if (!!poaId && !!poaResult) {
      r.transactionId = poaId;
      r.result = poaResult;
    }

    // this.$sentry.warning(`Receiver Jumio Url : ${location.href}`); // sentry logging 처리 주석
    window.parent.postMessage(r, '*');
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
</style>