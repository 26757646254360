<template>
  <div onboarding-verify-form>
    <template v-if="email">
      <template v-if="reaffirm">
        <p v-html="$t('_.reaffirm.de.guideEmailVerify')" />
        <p v-html="$t('_.reaffirm.de.descriptionEmailVerify', {userEmail: modelInfo.Email})" />
      </template>
      <template v-else>
        <p>{{ $t('onBoarding.setting.verifyMobileCode.description') }} {{ modelInfo.EmailMasked }}</p>
      </template>
    </template>
    <template v-else>
      <p v-html="`${$t('_.verifyMobileCode.description')} +${modelInfo.PhoneCountryCode} ${modelInfo.ContactMobileNumber || modelInfo.MobileNumber}`" />
    </template>
    <VerifyResendButton :structure="structure" :model-info="modelInfo" :action="action" @complete="resendComplete" />
    <ValidationProvider v-slot="{errors}" rules="requiredNone">
      <VerifyCode v-model="modelInfo.VerificationCode" :structure="structure" @input="updateCode" />
      <ValidationErrors v-if="structure.errorMsg" :errors="errors" :error-msg="structure.errorMsg" />
    </ValidationProvider>

    <OpenEmailApp v-if="email" :email="modelInfo.Email" />
    <ProgressButton :button-name="buttonName" @click="confirm" :disabled="structure.error || invalid || !codeLengthCheck" />
  </div>
</template>
<script>

import { state } from '@shared/utils/storeUtils.mjs';
import VerifyCode from '@/views/components/common/VerifyPinCode.vue';
import baseTemplate from '@/mixins/baseTemplate';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import Specific from '@shared/types/Specific';
import VerifyResendButton from '@/views/components/common/VerifyResendButton.vue';
import VerifyRequestMessageModal from '@/views/components/common/modal/VerifyRequestMessageModal.vue';
import OpenEmailApp from '@/views/components/common/OpenEmailApp.vue';
import { apiErrorType } from '@/constants/base/apiErrorCode';

export default {
  name: 'VerifyForm',
  components: { VerifyResendButton, ProgressButton, VerifyCode, OpenEmailApp },
  lexicon: 'onBoarding.setting',
  mixins: [baseTemplate],
  props: {
    invalid: { type: Specific, default: null },
  },
  computed: {
    site: state('env', 'site'),
    customStep: state('query', 'customStep'),
    csEmail: state('env', 'csEmail'),
    step() {
      return this.structure.step;
    },
    lastStep() {
      return this.step >= this.structure.list.length - 1;
    },
    currentItem() {
      return this.structure?.list[this.step];
    },
    buttonName() {
      return this.currentItem.buttonName || (this.lastStep ? 'submit' : 'next');
    },
    service() {
      return this.currentItem.service[this.site] || this.currentItem.service.default || this.currentItem.service;
    },
    action() {
      return this.email ? 'EmailVerifySendCode' : 'MobileVerifySendCode';
    },
    api() {
      return this.currentItem?.api;
    },
    email() {
      return this.modelInfo.SendVerificationType === 'EMAIL';
    },
    reaffirm() {
      return this.customStep === 'PersonalDetailReaffirm';
    },
    codeLengthCheck() {
      return this.modelInfo.VerificationCode && this.modelInfo.VerificationCode.length >= 4;
    },
  },
  methods: {
    updateCode(v) {
      this.modelInfo.VerificationCode = v;
    },
    /**
     * 다음 단계로 이동
     */
    nextStep() {
      this.modelInfo.step = 1;
    },
    /**
     * 컴포넌트 내에서의 api request 처리
     * @returns {Promise<*|null>}
     */
    async apiRequest() {
      const r = await this.getApiResponse();
      if (r.error) {
        /*
        * EMAIL_NOT_FOUND
        * INVALID_EMAIL
        * EMAIL_RESEND_LIMITED
        * EMAIL_RESEND_TERM_LIMITED
        * EMAIL_EXISTS
        * EXCEED_VERIFICATION_CODE_SEND_COUNT
        * REJECT_FROM_BGC
        * */
        this.structure.error = true;
        this.structure.errorMsg = this.$t(r.key, { csEmail: this.csEmail });
        this.structure.errorDesc = r.desc;

        if (process.env.VUE_APP_ENV !== 'production') {
          console.log(`Api Request Error : onboarding.${this.api}`, r);
          this.$toast(r.desc, { type: 'fail', translate: false });
        }
      }
    },
    /**
     *
     * @returns {Promise<*|{value, error, key, desc, CustomerErrorParameters}>}
     */
    async getApiResponse() {
      try { return /** @type {{ value, error, key, desc, CustomerErrorParameters}} */ await this.$services[this.service][this.api]({ ...this.structure.model }); } catch (e) { return e; }
    },
    /**
     * 다음 단계 이동
     * @returns {Promise<void>}
     */
    async confirm() {
      await this.apiRequest();
      // GGPRO일 경우 모바일 인증번호 진행 후 창닫힘 처리
      // [Eric] Test 환경에서는 Complete 처리가 되어 동일 처리 진행
      if (!this.invalid && !this.structure.error) {
        // this.site === siteIds.GGPRO ? close() : this.nextStep();
        this.nextStep();
      }
    },
    async resendComplete(v) {
      if (v.error) {
        const { CustomerErrorParameters, errorTemplate } = v.error;
        if (errorTemplate?.[apiErrorType.AUTH]) {
          return { path: 'AuthError', params: { ci: errorTemplate.ci, cci: errorTemplate.cci }, query: { p: CustomerErrorParameters ? encodeURIComponent(CustomerErrorParameters) : undefined } };
        }
      }

      await this.$modal(VerifyRequestMessageModal, { isSend: v?.isSend, type: this.modelInfo.SendVerificationType === 'EMAIL' ? 'email' : 'sms', error: v?.error });
    },
  },
  mounted() {
    this.item.title = this.reaffirm ? 'onBoarding.reaffirm.de.title' : 'onBoarding.title.accountVerification';
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[onboarding-verify-form] { .rel();
  > p { .c(#d8d8d8);
    em { .c(white); }
  }
  [validation-errors] { .tc(); .mt(20); }
  > [verify-resend] { .mt(20); .tr(); }
  //.error { .c(@c-red); .fs(14); .medium(); .tc(); .abs(); .lb(50%, -30); .t-xc(); }
  > [open-email-app] { .mt(100); .flex-center(); }
}
</style>