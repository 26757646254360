import { completeRemoveCookie, isExcludeGgpass } from '@/utils/baseUtil';
import { getCookie } from '@shared/utils/cookieUtils';
import { parseQuery } from '@shared/utils/urlUtils';
import { sleep } from '@shared/utils/commonUtils';
import ClientRequestMessageModal from '@/views/components/common/modal/ClientRequestMessageModal.vue';
import emailList from '@/constants/base/email';

const DEV_TIMEOUT_MILLISECOND = 1000;

/**
 * 창 닫기
 */
export const close = async scope => {
  if (typeof window === 'undefined') return;

  try {
    let setTimeoutMillisecond = 0;
    const message = { message: 'CLOSE_WEBVIEW' };
    if (process.env.VUE_APP_ENV !== 'production') {
      setTimeoutMillisecond = DEV_TIMEOUT_MILLISECOND;
      await scope?.$modal(ClientRequestMessageModal, { message });
    }

    if (setTimeoutMillisecond) await sleep(setTimeoutMillisecond);
    console.log('%cclientUtil close', 'color:blue', JSON.stringify(message));
    window.postMessage(message);
    window.close();
  } catch (e) { /* empty */ }
};

/**
 * Dimmed 설정 요청
 * @param scope
 * @returns {Promise<void>}
 */
export const onDimmed = async scope => {
  if (typeof window === 'undefined') return;

  try {
    const message = { message: 'DIMMED_ON'};
    let setTimeoutMillisecond = 0;
    if (process.env.VUE_APP_ENV !== 'production') {
      setTimeoutMillisecond = DEV_TIMEOUT_MILLISECOND;
      await scope?.$modal(ClientRequestMessageModal, { message });
    }

    if (setTimeoutMillisecond) await sleep(setTimeoutMillisecond);

    console.log('%cclientUtil onDimmed', 'color:blue', JSON.stringify(message));
    window.postMessage(message);
  } catch (e) { /* empty */ }
};

/**
 * Dimmed 해제 요청
 * @param scope
 * @returns {Promise<void>}
 */
export const offDimmed = async scope => {
  if (typeof window === 'undefined') return;

  try {
    const message = { message: 'DIMMED_OFF'};

    let setTimeoutMillisecond = 0;
    if (process.env.VUE_APP_ENV !== 'production') {
      setTimeoutMillisecond = DEV_TIMEOUT_MILLISECOND;
      await scope?.$modal(ClientRequestMessageModal, { message });
    }

    if (setTimeoutMillisecond) await sleep(setTimeoutMillisecond);

    console.log('%cclientUtil offDimmed', 'color:blue', JSON.stringify(message));
    window.postMessage(message);
  } catch (e) { /* empty */ }
};

/**
 * 외부 브라우저 열기 요청
 * @param scope
 * @param url
 * @returns {Promise<void>}
 */
export const openWeb = async (scope, url) => {
  if (typeof window === 'undefined') return;

  try {
    const queries = parseQuery(location.search);
    const isFromClientApp = getCookie('isFromClientApp');
    const isFromMobileApp = getCookie('isFromMobileApp');

    let message;
    if (queries?.isFromClientApp === 'true' || queries?.isFromMobileApp === 'true' || isFromClientApp || isFromMobileApp) message = { message: 'OPEN_BROWSER', url };
    else message = { message: 'OPEN_BROWSER', url };

    let setTimeoutMillisecond = 0;
    if (process.env.VUE_APP_ENV !== 'production') {
      setTimeoutMillisecond = DEV_TIMEOUT_MILLISECOND;
      await scope?.$modal(ClientRequestMessageModal, { message });
    }

    if (setTimeoutMillisecond) await sleep(setTimeoutMillisecond);

    console.log('%cclientUtil openWeb', 'color:blue', JSON.stringify(message));
    window.postMessage(message);
  } catch (e) { /* empty */ }
};

/**
 * 이메일 열기 요청
 * @param scope
 * @param email
 * @returns {Promise<void>}
 */
export const openEmailApp = async (scope, email) => {
  if (typeof window === 'undefined') return;

  try {
    const queries = parseQuery(location.search);
    const isFromClientApp = getCookie('isFromClientApp') || queries?.isFromClientApp === 'true';
    const isFromMobileApp = getCookie('isFromMobileApp') || queries?.isFromMobileApp === 'true';

    let url;
    const [id, domain] = email.split('@');
    const findDomain = emailList.find(email => email.label === domain);

    if (findDomain) url = findDomain.value;
    else url = domain;

    if (isFromClientApp) {
      await openWeb(scope, url);
    } else {
      const message = isFromMobileApp ? { message: 'NAVIGATE_MAIL_APP' } : '';

      let setTimeoutMillisecond = 0;
      if (process.env.VUE_APP_ENV !== 'production') {
        setTimeoutMillisecond = DEV_TIMEOUT_MILLISECOND;
        await scope?.$modal(ClientRequestMessageModal, { message });
      }

      if (setTimeoutMillisecond) await sleep(setTimeoutMillisecond);

      console.log('%cclientUtil openEmailApp', 'color:blue', JSON.stringify(message));
      if (!message) window.open(url, 'blank');
      else window.postMessage(message);
    }
  } catch (e) { /* empty */ }
};

/**
 * auto login
 * @param siteId
 * @param doLogin
 * @param email
 * @param password
 * @param mobileNumber
 */
export const autoLogin = async (scope, { doLogin, email, password, mobileNumber } = {}) => {
  if (typeof window === 'undefined') return;

  completeRemoveCookie();
  const userAddress = email ? encodeURIComponent(email) : undefined;
  const userPassword = password ? encodeURIComponent(password) : undefined;
  const userMobileNumber = mobileNumber ? encodeURIComponent(mobileNumber) : undefined;
  const npToken = scope.$services.store.state.user.token;
  const siteId = scope.$services.store.state.env.site;
  let oneTimeToken;
  if (npToken) oneTimeToken = (!isExcludeGgpass(siteId) && npToken) ? await scope.$services.sign.getGcLoginToken() : undefined;
  let setTimeoutMillisecond = 0;
  const message = password ? { message: 'AUTO_LOGIN', siteId, doLogin, token: oneTimeToken, userAddress, userPassword, userMobileNumber } : { message: 'PLAYER_VERIFICATION', status: 'Complete', siteId, token: oneTimeToken };
  if (process.env.VUE_APP_ENV !== 'production') {
    setTimeoutMillisecond = DEV_TIMEOUT_MILLISECOND;
    await scope?.$modal(ClientRequestMessageModal, { message });
  }

  if (setTimeoutMillisecond) await sleep(setTimeoutMillisecond);
  console.log('%cclientUtil autoLogin', 'color:blue', JSON.stringify(message));
  window.postMessage(message);
};

/**
 * citizenship Verification 시 연동 호출
 */
export const citizenshipVerification = async scope => {
  if (typeof window === 'undefined') return;

  let setTimeoutMillisecond = 0;
  const message = { message: 'CITIZENSHIP_VERIFICATION' };
  if (process.env.VUE_APP_ENV !== 'production') {
    setTimeoutMillisecond = DEV_TIMEOUT_MILLISECOND;
    await scope?.$modal(ClientRequestMessageModal, { message });
  }

  if (setTimeoutMillisecond) await sleep(setTimeoutMillisecond);
  console.log('%ccitizenshipVerification', 'color:blue', JSON.stringify(message));
  window.postMessage(message);
};

/**
 * client 카메라 권한 유무 체크
 */
export const checkClientPermission = async scope => {
  if (typeof window === 'undefined') return;

  let setTimeoutMillisecond = 0;
  const message = { message: 'HAS_PERMISSION_CAMERA' };
  if (process.env.VUE_APP_ENV !== 'production') {
    setTimeoutMillisecond = DEV_TIMEOUT_MILLISECOND;
    await scope?.$modal(ClientRequestMessageModal, { message });
  }

  if (setTimeoutMillisecond) await sleep(setTimeoutMillisecond);
  console.log('%cclientUtil checkClientPermission', 'color:blue', JSON.stringify(message));
  window.postMessage(message);
};

/**
 * client 카메라 권한 요청
 */
export const requestClientPermission = async scope => {
  if (typeof window === 'undefined') return;

  let setTimeoutMillisecond = 0;
  const message = { message: 'REQUEST_PERMISSION_CAMERA' };
  if (process.env.VUE_APP_ENV !== 'production') {
    setTimeoutMillisecond = DEV_TIMEOUT_MILLISECOND;
    await scope?.$modal(ClientRequestMessageModal, { message });
  }

  if (setTimeoutMillisecond) await sleep(setTimeoutMillisecond);
  console.log('%cclientUtil requestClientPermission', 'color:blue', JSON.stringify(message));
  window.postMessage(message);
};

/**
 * client 설정 화면 이동 요청
 */
export const settingClientPermission = async scope => {
  if (typeof window === 'undefined') return;

  let setTimeoutMillisecond = 0;
  const message = { message: 'OPEN_APP_SETTING' };
  if (process.env.VUE_APP_ENV !== 'production') {
    setTimeoutMillisecond = DEV_TIMEOUT_MILLISECOND;
    await scope?.$modal(ClientRequestMessageModal, { message });
  }

  if (setTimeoutMillisecond) await sleep(setTimeoutMillisecond);
  console.log('%cclientUtil settingClientPermission', 'color:blue', JSON.stringify(message));
  window.postMessage(message);
};

/**
 * 클라이언트 여부 확인
 * @returns {string|string|*}
 */
export const isClient = () => {
  const queries = parseQuery(location.search);
  const isFromClientApp = getCookie('isFromClientApp');
  const isFromMobileApp = getCookie('isFromMobileApp');

  return queries?.isFromClientApp === 'true' || queries?.isFromMobileApp === 'true' || isFromClientApp || isFromMobileApp;
};

export const openBrowser = async (scope, url) => {
  if (typeof window === 'undefined') return;

  let setTimeoutMillisecond = 0;
  const message = { message: 'OPEN_BROWSER', url };
  if (process.env.VUE_APP_ENV !== 'production') {
    setTimeoutMillisecond = DEV_TIMEOUT_MILLISECOND;
    await scope?.$modal(ClientRequestMessageModal, { message });
  }

  if (setTimeoutMillisecond) await sleep(setTimeoutMillisecond);
  console.log('%copenBrowser', 'color:blue', JSON.stringify(message));
  window.postMessage(message);
};

export const webviewDebugger = async scope => {
  if (typeof window === 'undefined') return;

  let setTimeoutMillisecond = 0;
  const message = { message: 'WEBVIEW_DEBUGGER' };
  if (process.env.VUE_APP_ENV !== 'production') {
    setTimeoutMillisecond = DEV_TIMEOUT_MILLISECOND;
    await scope?.$modal(ClientRequestMessageModal, { message });
  }

  if (setTimeoutMillisecond) await sleep(setTimeoutMillisecond);
  console.log('%cwebviewDebugger', 'color:blue', JSON.stringify(message));
  window.postMessage(message);
};