<template>
  <div input-single-bet-limit>
    <LimitTableRow no-limit-name="DailyNoLimit" :rules="rules" :no-limit="model.NoLimit" current-name="_currentLimit" v-model="model.RequestSingleBetLimit" :current-value="currentAmount" new-name="_newLimit" :preset="preset" @checked="change" />
    <!--    <InputComponent :label="$t('currentLimit')" name="_currentLimit" :component="TextInputBox" :disabled="true" v-model="currentAmount" :preset="currentPreset" />-->
    <!--    <InputComponent :label="$t('newLimit')" name="_newLimit" :rules="rules" :component="TextInputBox" v-model="model.RequestSingleBetLimit" :preset="preset" :disabled="model.NoLimit" />-->
    <!--    <CheckboxItem theme="light" :selected="model.NoLimit" @change="change">{{ $t('noLimit') }}</CheckboxItem>-->
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import LimitTableRow from '@/views/components/pages/responsible-gaming/template/common/LimitTableRow.vue';
export default {
  name: 'InputSingleBetLimit',
  lexicon: 'onBoarding.setting',
  components: { LimitTableRow },
  props: {
    model: Specific,
    current: Specific,
    currencySymbol: { type: String, default: null }
  },
  computed: {
    currentAmount() {
      return this.createCurrentDisplayAmount(this.current.CurrentSingleBetLimit);
    },
    currentPreset() {
      return { type: 'amount', placeholder: this.$t('noLimit') };
    },
    preset() {
      return { placeholder: this.$t('amount'), textAlign: 'right', currencySymbol: this.currencySymbol, type: 'number', maxLength: 9 };
    },
    rules() {
      return `charLengthMax:9|rgMin:1|requiredLimit:${this.model.NoLimit}`;
    }
  },
  methods: {
    createCurrentDisplayAmount(value) {
      if(!value && value !== 0) return null
      return `${this.currencySymbol}${value}`;
    },
    change(name, v) {
      this.model.NoLimit = v;
      if (v) this.model.RequestSingleBetLimit = null;
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[input-single-bet-limit] {.flex(); .flex-dc(); .gap(20);
  [checkbox-item] {
    > em {line-height: 16px; .c(black); .fs(14);}
  }
}
</style>